import React from 'react';
import { ModalWrapper } from './ModalStyles';
import { Modal } from 'react-bootstrap';

// reusable modal component
const ModalComponent = ({
  children,
  handleShow,
  handleClose,
  isModalOpen,
  dialogClassName
}) => (
  <ModalWrapper>
    <Modal
      dialogClassName={dialogClassName}
      show={isModalOpen}
      onHide={handleClose}
    >
      {children}
    </Modal>
  </ModalWrapper>
);

export default ModalComponent;
