import { VALIDATE_TOKEN } from './Types';
import { LOADING_TOKEN_START } from './Types';
import { LOADING_TOKEN_END } from './Types';

const isTokenValid =  async ( token = null ) => {


  const options = {
    method: 'GET',
    headers: {
      'Authorization' : `Bearer ${ token }`
    }
  }
  const USIK_URL = process.env.REACT_APP_USIK_URL
  let res = await fetch( `${USIK_URL}/api/me`, options )
  let data = await res.json()

  if( token === null || data.status ) {
    return false
  }
  return data
}

export const validateToken = (token) => async dispatch => {
  dispatch({ type: LOADING_TOKEN_START })
  try {
    const data = await isTokenValid( token )
    const payload = {
      isTokenValid: data ? true : false,
      user: data
    }
    dispatch({ type: VALIDATE_TOKEN, payload: payload})
  } catch(e) {
    console.log(e)
  } finally {
    dispatch({ type: LOADING_TOKEN_END})
  }

};
