import React, { Component } from 'react';
import { ArrowButtonWrapper } from './ArrowButtonStyles';

const rotateArrow = { transform: 'rotate(180deg)' };

class ArrowButton extends Component {
  state = {
    isCollapsed: this.props.isCollapsed
  };

  // componentDidMount() {
  //   this.handleResize();
  //   window.addEventListener('resize', this.handleResize);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleResize);
  // }

  // // on window resize close collapse and rotate arrow
  // handleResize = () => {
  //   this.setState({
  //     isCollapsed: false
  //   });
  // };

  handleCollapseVisibility = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    const { isCollapsed } = this.state;
    const { isStatic, isBasket, isArrowUp } = this.props;
    return (
      <ArrowButtonWrapper
        // arrow component has different styles if is used in basket component (isBasket props)
        isBasket={isBasket}
        // arrow component is static and not clickable if window width is less than 769
        onClick={isStatic ? null : this.handleCollapseVisibility}
        aria-expanded={isCollapsed}
      >
        {isStatic ? (
          // even if arrow component is static we can handle arrow direction with isArrowUp props
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='11'
            height='7'
            viewBox='0 0 11 7'
            style={isArrowUp ? rotateArrow : null}
          >
            <path
              id='Path_26'
              data-name='Path 26'
              d='M8.1,11.6,2.6,6.041,4.026,4.6,8.1,8.718,12.174,4.6,13.6,6.041Z'
              transform='translate(-2.6 -4.6)'
            />
          </svg>
        ) : (
          // props isCollapsed handle rotate animation
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='11'
            height='7'
            viewBox='0 0 11 7'
            style={isCollapsed ? rotateArrow : null}
          >
            <path
              id='Path_26'
              data-name='Path 26'
              d='M8.1,11.6,2.6,6.041,4.026,4.6,8.1,8.718,12.174,4.6,13.6,6.041Z'
              transform='translate(-2.6 -4.6)'
            />
          </svg>
        )}
      </ArrowButtonWrapper>
    );
  }
}

export default ArrowButton;
