import styled from 'styled-components';

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.dark.gray};
  height: 100%;
  position: relative;
  z-index: 10;
  svg {
    transition: transform 0.4s ease-in-out;
    fill: #a4afb7;
    cursor: pointer;
    @media (max-width: 550px) {
      display: none;
    }
  }
`;

export const UserNameWrapper = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
`;

export const UserName = styled.span`
  cursor: pointer;
  font-weight: 600;
  padding-right: 0.75em;
  padding-left: 1.5em;
  border-left: 1px solid ${({ theme }) => theme.light.gray};
  @media (max-width: 550px) {
    display: none;
  }
`;

export const UserNameResp = styled.span`
  cursor: pointer;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  display: none;
  @media (max-width: 550px) {
    display: flex;
  }
`;

export const UserImageWrapper = styled.div`
  cursor: pointer;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-left: 0.75em;
  @media (max-width: 550px) {
    margin-left: 0em;
  }
`;

export const UserImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const UserManagment = styled.div`
  position: absolute;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  top: calc(100% + 0.5em - 1px);
  opacity: ${({ isProfileOpen }) => (isProfileOpen ? 1 : 0)};
  left: 64px;
  right: -1em;
  background-color: ${({ theme }) => theme.light.white};
  z-index: 5;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.04);
  @media (max-width: 550px) {
    left: auto;
    min-width: 200px;
    max-width: 320px;
  }
`;

export const UserManagmentItem = styled.div`
  display: flex;
  justify-content: center;
`;

export const LogoutButton = styled.button`
  background-color: rgba(67, 66, 93, 0.33);
  color: ${({ theme }) => theme.dark.gray};
  border: 0;
  margin: 0;
  padding: 0;
  font-weight: 600;
  padding: 0.75em;
  width: 100%;
  display: flex;
  justify-content: center;
`;
