import styled from 'styled-components';

export const ArrowButtonWrapper = styled.div`
  padding: 0 1em 0 1.5em;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    border: 1px solid ${({ isBasket }) => (isBasket ? '#808495' : '#d7dae2')};
    width: 40px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  svg {
    transition: transform 0.2s ease-in-out;
    fill: #808495;
  }
`;

export const ArrowButtonContent = styled.div`
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  opacity: ${({ isBasketVisible }) => (isBasketVisible ? 1 : 0)};
`;
