import React, { Component } from 'react';
import NavodyItem from './NavodyItem';

class NavodyItemComponent extends Component {
  state = {
    isCollapsed: false
  };

  // componentDidMount() {
  //   this.handleResize();
  //   window.addEventListener('resize', this.handleResize);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleResize);
  // }

  // // if resizing close collapses
  // handleResize = () => {
  //   this.setState({
  //     isCollapsed: false
  //   });
  // };

  handleCollapseVisibility = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
    // send id of item as a props to NavodyScreen
    this.props.handleItemProps(this.props.stepNumber);
  };

  render() {
    const { isCollapsed } = this.state;
    const {
      stepNumber,
      title,
      name,
      id,
      price,
      videoURL,
      description,
      isLastItem,
      isResponsible,
      responsible,
      windowWidth,
      addToBasket,
      isTimerVisible,
      isPriceOffer,
      storyId
    } = this.props;

    return (
      <NavodyItem
        addToBasket={addToBasket}
        isCollapsed={isCollapsed}
        stepNumber={stepNumber}
        title={title}
        name={name}
        id={id}
        price={price}
        videoURL={videoURL}
        description={description}
        isLastItem={isLastItem}
        responsible={responsible}
        isResponsible={isResponsible}
        clickOnCollapse={this.handleCollapseVisibility}
        windowWidth={windowWidth}
        isTimerVisible={isTimerVisible}
        isPriceOffer={isPriceOffer}
        storyId={storyId}
      />
    );
  }
}

export default NavodyItemComponent;
