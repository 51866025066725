import React from 'react';
import {
  HorizontalLayout2Wrapper,
  HorizontalLayout2Left,
  HorizontalLayout2Right,
  Logo
} from './HorizontalLayout2Styles';
import BanikLogo from '../../assets/logo/banik-logo.png';

// reusable component for validation, registration, login and forgot password screen
const HorizontalLayout2 = ({ children }) => (
  <HorizontalLayout2Wrapper>
    <HorizontalLayout2Left />
    <HorizontalLayout2Right>
      {/* <div/> = because of adjusment of components (justify-content: space-between) = center children and set banik logo to the bottom of page */}
      <div />
      {children}
      <Logo src={BanikLogo} alt='Banik' />
    </HorizontalLayout2Right>
  </HorizontalLayout2Wrapper>
);

export default HorizontalLayout2;
