import React from 'react';
import styled from 'styled-components';

const Checkbox = ({ onChange, checked, label, htmlFor, name }) => (
  <Styled onClick={() => onChange(!checked)}>
    <input name={name} type='checkbox' checked={checked} />
    <label htmlFor={htmlFor}>{label}</label>
  </Styled>
);

export default Checkbox;

const Styled = styled.div`
  display: flex;
  position: relative;
  > input {
    opacity: 0;
    width: 17px;
    height: 17px;
  }
  > input + label {
    color: ${({ theme }) => theme.neutral.purple};
    margin: 0;
    display: flex;
    font-weight: 600;
    cursor: pointer;
    transition: font-size 0.2s ease-in-out;

    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 425px) {
      font-size: 15px;
    }
    padding-left: 10px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 4px;
      width: 17px;
      height: 17px;
      border: 2px solid #808495;
      background: ${({ theme }) => theme.light.white};
      border-radius: 5px;
    }
    &:after {
      content: '✔';
      position: absolute;
      top: 3px;
      left: 3px;
      font-size: 14px;
      color: ${({ theme }) => theme.light.white};
      transition: all 0.2s ease-in-out;
    }
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  input:checked + label {
    &::before {
      transition: all 0.2s ease-in-out;
      background: #808495;
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      opacity: 0.5;
    }
  }
  > input:checked + label {
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  > input:disabled:checked + label {
    &:after {
      opacity: 0.5;
    }
  }
  > input:disabled + label {
    opacity: 0.5;
  }
`;
