import React from 'react';
import { ModalNavodyWrapper, ModalNavodyButtonNext } from './ModalNavodyStyles';
import ModalComponent from '../../modal/ModalComponent';
import {
  NavodyItemWrapper,
  NavodyItemHeader,
  NavodyItemHeaderLeft,
  NavodyItemHeaderOrderNo,
  NavodyItemHeaderTitle,
  NavodyItemHeaderRight,
  NavodyItemHeaderButtonWrapper,
  NavodyItemHeaderPriceWrapper,
  NavodyItemHeaderButton,
  NavodyItemHeaderArrow,
  NavodyItemBodyContent,
  NavodyItemBodyTitle,
  NavodyItemBodySubTitle,
  NavodyItemBodyText,
  NavodyItemBodyPlayer,
  NavodyItemNumberFormat
} from '../item/NavodyItemStyles';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import ArrowButton from '../../arrow-button/ArrowButton';

// styles for scrolling content in modal
const parent = {
  display: 'table',
  height: 'calc(100vh)',
  width: '100%'
};

const child = {
  //display: 'table-cell',
  height: '100%',
  width: '100%'
};

const subParent = {
  height: '100%',
  display: 'table',
  width: '100%'
};

const subChild = {
  width: '100%',
  display: 'table-row'
};

const subChild2 = {
  width: '100%',
  display: 'table-row',
  height: 0
};

const contentWrapper = {
  width: '100%',
  height: '100%',
  position: 'relative'
};

const content = {
  // overflowY: 'auto',
  // position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 70
};

const ModalNavody = ({
  stepNumber,
  title,
  name,
  id,
  price,
  videoURL,
  description,
  responsible,
  respName,
  isModalOpen,
  handleShow,
  handleClose,
  handleNextItem,
  isLastItem,
  onSubmitButtonClick,
  isPriceOffer,
  video
}) => (
  <ModalNavodyWrapper>
    <ModalComponent
      // handle modal visibility
      handleShow={handleShow}
      handleClose={handleClose}
      isModalOpen={isModalOpen}
      dialogClassName='navody-modal'
    >
      <div style={parent}>
        <div style={child}>
          <NavodyItemWrapper style={subParent}>
            <NavodyItemBodyPlayer style={subChild2}>
              <Video
                autoPlay={false}
                key={video.id}
                loop
                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
              >
                <source src={videoURL} type='video/mp4' />
              </Video>
            </NavodyItemBodyPlayer>
            <div style={subChild}>
              <div style={contentWrapper}>
                <div style={content}>
                  <NavodyItemHeader>
                    <NavodyItemHeaderLeft>
                      <NavodyItemHeaderOrderNo>
                        {parseInt(stepNumber) + 1}
                      </NavodyItemHeaderOrderNo>
                      <NavodyItemHeaderTitle isPriceOffer={isPriceOffer}>
                        <h6>{title}</h6>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {/* <Rating readonly initialRating={rating} /> */}
                          <NavodyItemHeaderPriceWrapper>
                            <div>Cena kroku</div>
                            <span>
                              <NavodyItemNumberFormat
                                alignleft={true.toString()}
                                decimalSeparator={','}
                                suffix={'€'}
                                thousandSeparator={'.'}
                                value={price}
                                renderText={value => <div>{price}</div>}
                              />
                            </span>
                          </NavodyItemHeaderPriceWrapper>
                        </div>
                      </NavodyItemHeaderTitle>
                    </NavodyItemHeaderLeft>
                    <NavodyItemHeaderRight>
                      {respName === false ? (
                        <NavodyItemHeaderButtonWrapper>
                        <NavodyItemHeaderButton isDisabled={true}>
                          <input
                            checked={true}
                            id={`zasah${id}`}
                            name={name}
                            type='radio'
                            disabled
                          />
                          <label htmlFor={`zasah${id}`}>
                            Zabezpečujeme potrebný odborný zásah
                          </label>
                        </NavodyItemHeaderButton>
                      </NavodyItemHeaderButtonWrapper>
                    ) : (
                      <NavodyItemHeaderButtonWrapper onChange={(event) => onFormChange(event)}>
                        <NavodyItemHeaderButton>
                          <input
                            checked={!responsible}
                            id={`necham${id}`}
                            name={name}
                            type='radio'
                            value={false}
                          />
                          <label htmlFor={`necham${id}`}>Nechám si to spraviť</label>
                        </NavodyItemHeaderButton>
                        <NavodyItemHeaderButton>
                          <input
                            checked={responsible}
                            id={`spravim${id}`}
                            name={name}
                            type='radio'
                            value={true}
                          />
                          <label htmlFor={`spravim${id}`}>Spravím si to sám</label>
                        </NavodyItemHeaderButton>
                      </NavodyItemHeaderButtonWrapper>
                      )}

                      <NavodyItemHeaderArrow onClick={handleClose}>
                        <ArrowButton isArrowUp isStatic />
                      </NavodyItemHeaderArrow>
                    </NavodyItemHeaderRight>
                  </NavodyItemHeader>
                  <NavodyItemBodyContent>
                    <NavodyItemBodyTitle>{title}</NavodyItemBodyTitle>
                    <NavodyItemBodySubTitle>
                      Popis inštruktážného videa
                    </NavodyItemBodySubTitle>
                    <NavodyItemBodyText>{description}</NavodyItemBodyText>
                  </NavodyItemBodyContent>
                </div>
              </div>
            </div>
          </NavodyItemWrapper>
        </div>
      </div>
      {isLastItem ? (
        <ModalNavodyButtonNext onClick={onSubmitButtonClick}>
          Odoslať cenovú ponuku
        </ModalNavodyButtonNext>
      ) : (
        <ModalNavodyButtonNext onClick={handleNextItem}>
          Pokračovať na ďalší krok
        </ModalNavodyButtonNext>
      )}
    </ModalComponent>
  </ModalNavodyWrapper>
);

export default ModalNavody;
