// handle form validation
export const formValid = data => {
  const { formErrors, ...rest } = data;
  let valid = true;
  Object.values(formErrors).forEach(val => val.length > 0 && (valid = false));

  Object.values(rest).forEach(val => {
    (val === '' || val === false) && (valid = false);
  });

  return valid;
};

// REGULAR EXPRESSIONS
export const isEmailValid = email => {
  const emailRegex = RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return emailRegex.test(email);
};

export const isPasswordValid = password => {
  const passwordRegex = RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
  );
  return passwordRegex.test(password);
};

export const isPhoneNumberValid = phoneNumber => {
  const phoneRegex = RegExp(/^[+]?[()/0-9. -]{9,15}$/);
  return phoneRegex.test(phoneNumber);
};

export const isNameValid = name => {
  const nameRegex = RegExp(/^([a-zA-Z]{2,})$/);
  return nameRegex.test(name);
};
// REGULAR EXPRESSIONS
