import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  background-color: ${({ theme }) => theme.light.grayDash};
  min-height: 100vh;
  height: 100%;
`;

export const NavodyScreensWrapper = styled.div`
  transition: all 0.5s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);
  height: 100%;
  justify-content: space-between;
  padding-bottom: ${({ isPaddingBottom90 }) => isPaddingBottom90 ? '90px' : '0'};

  @media (max-width: 768px) {
    min-height: calc(100vh - 123px);
    padding-bottom: ${({ isPaddingBottom90 }) => isPaddingBottom90 ? '130px' : '0'};
    //padding-top: ${({ isTimerVisible, isPriceOffer }) => ( isPriceOffer ? ( isTimerVisible ? '76px' : '0' ) : ( isTimerVisible ? '123px' : '72px' ) )};
  }

  @media (max-width: 425px) {
    padding-bottom: ${({ isPaddingBottom90 }) => isPaddingBottom90 ? '120px' : '0'};
  }

  @media (max-width: 324px) {
    //padding-top: ${({ isTimerVisible, isPriceOffer }) => ( isPriceOffer ? ( isTimerVisible ? '60px' : '0' ) : ( isTimerVisible ? '107px' : '56px' ) )};
  }
`;
