import React, { Component } from 'react';
import HorizontalLayout2 from '../components/horizontal-layout2/HorizontalLayout2';
import ConfirmForm from '../components/form/Form';
import { Link } from '@reach/router';

class RecoverSuccessScreen extends Component {
  render() {
    return (
      <div>
        <HorizontalLayout2>
          <ConfirmForm
            formTitle='DOKONČENÉ'
            formAbout='Obnova hesla prebehla úspešne, môžete sa prihlásiť'
            isPersonalInfoVisible={false}
            isAlreadyUser='false'
            submitButtonText='Prihlásiť'
            onSubmit={() => {
              this.props.navigate('/login');
            }}
            alreadyUserHref=''
            disabled={false}
          >
            <br />
            <Link to="/">Späť na hlavnú stránku</Link>
          </ConfirmForm>
        </HorizontalLayout2>
      </div>
    );
  }
}

export default RecoverSuccessScreen;
