import styled from 'styled-components';
import { DelayInput } from 'react-delay-input';

export const FormInputWrapper = styled.div`
  display: flex;
  width: 100%;
  &:first-child {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormInputType = styled(DelayInput)`
  border: 0;
  margin: 0;
  margin-top: 30px;
  width: 100%;
  flex: 1;
  padding: 5px 10px;
  font-size: 17px;
  transition: border-color 0.2s ease-in-out;
  outline: 0;
  background: none;
  border-bottom: 2px solid ${({ theme }) => theme.light.gray};
  &:focus {
    border-color: ${({ theme }) => theme.neutral.purple};
  }
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    font-size: 16px;
    margin-top: 25px;
    padding: 5px 10px 6px;
  }
  @media (max-width: 425px) {
    width: calc(100% - 20px);
    font-size: 14px;
    margin-top: 20px;
  }
`;

export const FormInputBlankSpace = styled.span`
  width: 30px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.neutral.red};
  font-size: 13px;
  line-height: 16px;
`;
