import { REGISTER } from '../actions/Types';

const initialState = {
  data: null,
  error: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        data: action.payload.data,
        error: action.payload.error
      };
    default:
      return state;
  }
}
