import React, { Component } from 'react';
import HorizontalLayout2 from '../components/horizontal-layout2/HorizontalLayout2';
import ValidationForm from '../components/form/Form';
import FormInput from '../components/form/FormInput';
import {
  FormInputWrapper,
  ErrorMessage
} from '../components/form/FormInputStyles';
import { isEmailValid, isPasswordValid } from '../utils/utils';
import { connect } from 'react-redux';
import { loginUser } from '../actions/LoginActions';
import { Redirect } from '@reach/router';
 

class LoginScreen extends Component {

  state = {
    email: '',
    password: '',
    isEmail: false,
    isPassword: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const { isEmail, isPassword, email, password } = this.state;

    if (isEmail && isPassword) {
      this.props.loginUser( {email: email, password: password} );
    } else {
      console.error('FORM INVALID -DISPLAY ERROR MESSAGE');
    }
  };

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });

    switch (name) {
      case 'email':
        this.setState({ isEmail: isEmailValid(value) });
        break;
      case 'password':
        this.setState({ isPassword: value.length > 0 ? true : false });
        break;
      default:
        break;
    }
  };
  
  render() {
    const { isEmail, isPassword } = this.state;
    const { data, error, token } = this.props.login;

    if (error) {
      return (<Redirect noThrow to="/error/Nesprávne meno alebo heslo"/>);
    }

    if (token && data) {
      return (<Redirect noThrow to="/dashboard" />);
    }

    return (
      <div>
        <HorizontalLayout2>
          <ValidationForm
            formTitle='PRIHLÁSENIE'
            formAbout='Vitajte späť, prihláste sa'
            isPersonalInfoVisible={false}
            isForgotPasswordVisible='true'
            submitButtonText='Prihlásiť'
            isAlreadyUser='true'
            alreadyUserHref='/registration'
            alreadyUserText='Nemáte účet? Registrujte sa'
            onSubmit={this.handleSubmit}
            disabled={isEmail && isPassword ? false : true}
          >
            <FormInputWrapper>
              <FormInput
                name='email'
                placeholder='E-mail'
                type='email'
                required={true}
                onChange={e => this.handleChange(e)}
              />
            </FormInputWrapper>
            <FormInputWrapper>
              <FormInput
                name='password'
                placeholder='Heslo'
                type='password'
                required={true}
                onChange={e => this.handleChange(e)}
              />
            </FormInputWrapper>
          </ValidationForm>
        </HorizontalLayout2>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  // token: state.token
});

const mapDispatchToProps = {
  loginUser: loginUser,
  // validateToken: validateToken
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
