import React, { Component } from 'react';
import { Redirect, navigate } from '@reach/router';
import { NavodyScreensWrapper } from '../components/basic/BasicStyles';
import NavodyHeader from '../components/navody/header/NavodyHeader';
import NavodyVideos from '../components/navody/videos/NavodyVideos';
import NavodyBasket from '../components/navody/basket/NavodyBasketWrapper';
import { NavodyCustomWrapper } from '../components/navody/NavodyStyles';
import Check from '../components/check/Check';
import construction from '../mock/construction.json';
import Spinner from 'react-bootstrap/Spinner'
import {
  handleBasketItems,
  handleSavePrice,
  fixRound,
  normalizeData,
  processData
} from '../utils/navody';
import ModalNavody from '../components/navody/modal-navody/ModalNavody';
import { connect } from 'react-redux';
import { fetchData } from '../actions/MainActions';
import { loginUser, removeToken } from '../actions/LoginActions';
import animateScrollTo from 'animated-scroll-to';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withCookies, Cookies } from 'react-cookie';

class NavodyScreen extends Component {

  state = {
    usikId: null,
    isTitleEditable: false,
    constructionNumber: null,
    constructionName: null,
    selectedConstruction: 0,
    originalPriceOffer: 0,
    windowWidth: undefined,
    basket: {},
    diy_tasks: [],
    isBasketVisible: false,
    data: [],
    stories: [],
    itemId: 0,
    isModalOpen: false,
    isLoading: true,
    error: false,
    codeExpiration: null
  };

  componentDidMount() {
    const { isPriceOffer, login, data, selectedConstruction, cookies } = this.props
    if ( login.token ) {
      localStorage.setItem( 'token', login.token );
      // cookies.set('jwt-token', login.token, {
      //   secure: true,
      //   httpOnly: true
      // });
      this.props.removeToken( login.data )
    }

    if( data.message || data.length === 0) {
      this.setState(
        {
          error: true
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    } else if ( this.state.data.length === 0 && data.length > 0 && !isPriceOffer ) {

      let steps = []

      data[selectedConstruction].stories.forEach( story => {
        let tasks = story.tasks 
        tasks.forEach( task => steps = [ ...steps, task ] )
      });
      
      this.setState(
        {
          usikId: data[selectedConstruction].id,
          constructionNumber: data[selectedConstruction].job_id,
          constructionName: data[selectedConstruction].name,
          originalPriceOffer: data[selectedConstruction].total_cost,
          selectedConstruction: data[selectedConstruction].length - 1,
          basket: handleBasketItems(steps),
          data: normalizeData(steps),
          stories: data[selectedConstruction].stories
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    }

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate( prevProps ) {

    if( ( (this.props.data !== prevProps.data) && prevProps.data === false ) || (this.props.selectedConstruction !== prevProps.selectedConstruction) ) {
      const { isPriceOffer, data, selectedConstruction, cookies } = this.props
      const token = localStorage.getItem('token')
      let steps = []
      
      if( data.message || data.length === 0 ) {
        this.setState(
          {
            error: true
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      } else if ( isPriceOffer ) {
  
        data.stories.forEach( story => {
          let tasks = story.tasks 
          tasks.forEach( task => steps = [ ...steps, task ] )
        });

        this.setState(
          {
            usikId: data.id,
            constructionNumber: data.job_id,
            constructionName: data.name,
            originalPriceOffer: typeof data.total_cost === 'string' ? parseInt(data.total_cost) : data.total_cost,
            selectedConstruction: data.length - 1,
            basket: handleBasketItems(steps),
            data: normalizeData(steps),
            stories: data.stories,
            codeExpiration: this.handleDate(data.expires_at)
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
    
      } else if ( token ) {
        // cookies.set('jwt-token', token, {
        //   secure: true,
        //   httpOnly: true
        // });

        data[selectedConstruction].stories.forEach( story => {
          let tasks = story.tasks 
          tasks.forEach( task => steps = [ ...steps, task ] )
        });

        this.setState(
          {
            usikId: data[selectedConstruction].id,
            constructionNumber: data[selectedConstruction].job_id,
            constructionName: data[selectedConstruction].name,
            originalPriceOffer: data[selectedConstruction].total_cost,
            selectedConstruction: data[selectedConstruction].length - 1,
            basket: handleBasketItems(steps),
            data: normalizeData(steps),
            stories: data[selectedConstruction].stories
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
    
      }
    }

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.outerWidth
    });
  };

  handleSelect = e => {
    console.log(e);
  };

  handleDate = unformatedDate => {
    let date = new Date( unformatedDate );

    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    let year = date.getFullYear()

    let hours = date.getHours()
    let min = date.getMinutes()

    return `${day}.${month}.${year} o ${hours}:${min}`
  };

  handleTitle = async e => {

    this.setState({ isTitleEditable: !this.state.isTitleEditable });
    const { usikId, constructionName } = this.state

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${ localStorage.getItem('token') || null }`
      },
      body: JSON.stringify( { name: constructionName } )
    }
    await processData( `/api/usik/rename/${usikId}`, options )

  };

  handleKeyDown = async e => {
    if (e.key === 'Enter') {
      this.handleTitle()
    }
  }

  handleConstructionName = e => {
    this.setState({ constructionName: e.target.value });
  };

  handleBasketVisibility = () => {
    this.setState({ isBasketVisible: !this.state.isBasketVisible });
  };

  addToBasket = (value, id, price) => {
    const { basket } = this.state;
    const { diy_tasks } = this.state;

    if( !diy_tasks.includes(id) && value ) {
      this.setState({
        diy_tasks: [
          ...diy_tasks,
          id
        ]
      })
    } else if ( diy_tasks.includes(id) && !value ) {
      let diy_tasks_temp = this.state.diy_tasks
      diy_tasks_temp.splice( diy_tasks_temp.indexOf(id), 1 ) 
      this.setState({
        diy_tasks: diy_tasks_temp
      })
    }

    this.setState(state => {
      return {
        basket: {
          ...basket,
          [id]: {
            id,
            assemble_price: price,
            diy_selected: value
          }
        }
      };
    });

    this.setState(prevState => ({
      data: prevState.data.map(item => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          diy_selected: value
        };
      })
    }));
  };

  handleItemProps = id => {
    this.setState({ itemId: id - 1, isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
    // after closing modal scroll to last element was in modal
    animateScrollTo(document.querySelector(`#item${this.state.itemId}`));
  };

  handleNextItem = () => {
    this.setState({ itemId: this.state.itemId + 1 });
  };

  handleSubmit = async () => {

    const code = this.props.code
    const tasks = { 
      diy_tasks: this.state.diy_tasks
    }
  
    // send tasks to backend
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify( tasks )
    } 
    await processData( `/api/usik/code/${code}`, options )
    navigate( '/login' )
  };

  submitOffer = () => {
    if(this.state.isModalOpen) {
      this.setState({isModalOpen: false})
    }
    confirmAlert({
      // title: 'Cenova ponuka',
      message: 'Ďakujeme za odoslanie upravenej ponuky. Zapracujeme ju a vystavíme zálohovú faktúru. Posielame vám teraz email s prihlásením do rozhrania urobsikurenie.sk',
      buttons: [
        {
          label: 'OK',
          onClick: () => this.handleSubmit()
        },
        {
          label: 'Zrušiť'
        }
      ]
    });
  };

  getVideoUrl = ( video, isPriceOffer ) => {
    const USIK_URL = process.env.REACT_APP_USIK_URL
    const code = this.props.code
    if( video != null ) {
      const token = localStorage.getItem('token')
      let apiUrl = isPriceOffer ? "/api/usik/video" : "/api/video/display"
      let query = isPriceOffer && `?video_id=${video.id}&code_id=${code}` || `?video_id=${video.id}&jwt-token=${token}`
      let src = `${USIK_URL}${apiUrl}${query}`
      return src
    }
    return ""
  }

  render() {
    
    const {
      isTitleEditable,
      constructionNumber,
      constructionName,
      originalPriceOffer,
      basket,
      windowWidth,
      isBasketVisible,
      isModalOpen,
      data,
      stories,
      itemId,
      isLoading,
      error,
      codeExpiration
    } = this.state;
    const { isTimerVisible, isPriceOffer, code } = this.props;
    
    if ( isLoading ) {
      return <Spinner variant="primary" style={{position: 'absolute', top:'47%', left: '47%'}} animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
    }

    if( error && isPriceOffer ) {
      return (<Redirect noThrow to="/error/Kód nie je platný"/>)
    } else if( error && !isPriceOffer ) {
      return (
        <Check 
          header={'Chyba!'} 
          text={'Momentálne nemáte žiadny aktívny projekt. Ak máte záujem o nejaký projekt, možte si vypočítať nezáväznú cenovú ponuku na nižšie uvedenom odkaze'}
          url={'http://www.urobsikurenie.sk'}
          urlText={'Vypočítať cenovú ponuku'}
        />
      )
    }

    if( !isPriceOffer && !this.props.data[this.props.selectedConstruction].has_deposit ) {
      // if( !this.props.data[this.props.selectedConstruction].has_deposit ) {
        return ( 
          <Check 
            header={'Upozornenie!'} 
            text={'Čaká sa na potvrdenie novej ceny práce.'}
            // url={'#'}
            // urlText={'Lorem ipsum dolor sit amet'}
          />
        )
      // }
    }
    return (
      // isPaddingBottom90 = NavodyScreensWrapper is reusable component which can be used in NAVODY DIY where is not isPaddingBottom90
      <NavodyScreensWrapper isPaddingBottom90 isTimerVisible={isTimerVisible} isPriceOffer={isPriceOffer}>
        <NavodyCustomWrapper>
          <NavodyHeader
            isPriceOffer={isPriceOffer}
            code={code}
            codeExpiration={codeExpiration}
            constructionNumber={constructionNumber}
            constructionName={constructionName}
            onInputChange={this.handleConstructionName}
            data={construction}
            isTitleEditable={isTitleEditable}
            onEditIconClick={this.handleTitle}
            onBlur={this.handleTitle}
            onKeyDown={this.handleKeyDown}
            originalPriceOffer={originalPriceOffer}
          />
          <NavodyVideos
            addToBasket={this.addToBasket}
            handleItemProps={this.handleItemProps}
            windowWidth={windowWidth}
            data={data}
            stories={stories}
            isTimerVisible={isTimerVisible}
            isPriceOffer={isPriceOffer}
            code={code}
            getVideoUrl={this.getVideoUrl}
          />
        </NavodyCustomWrapper>
        { isPriceOffer && (
          <NavodyBasket
            windowWidth={windowWidth}
            originalPriceValue={originalPriceOffer}
            saveValue={handleSavePrice(basket)}
            newPriceValue={fixRound(originalPriceOffer - handleSavePrice(basket))}
            onSubmitButtonClick={this.submitOffer}
            onBasketArrowClick={this.handleBasketVisibility}
            isBasketVisible={isBasketVisible}
            stories={stories}
          />
        )}
        {windowWidth < 769 && this.state.isModalOpen && (
          <ModalNavody
            handleClose={this.handleClose}
            isModalOpen={isModalOpen}
            stepNumber={data[itemId].order}
            title={data[itemId].title}
            name={data[itemId].id}
            id={data[itemId].id}
            price={typeof data[itemId].assemble_price === "string" ? parseInt(data[itemId].assemble_price) : data[itemId].assemble_price }
            videoURL={this.getVideoUrl( data[itemId].video, true )}
            video={data[itemId].video}
            description={data[itemId].description}
            isLastItem={data[itemId].isLastItem}
            responsible={data[itemId].diy_selected}
            respName={data[itemId].enable_diy}
            handleNextItem={this.handleNextItem}
            onSubmitButtonClick={this.submitOffer}
            isPriceOffer={isPriceOffer}
          />
        )}
      </NavodyScreensWrapper>
    );
  }
}

const mapStateToProps = state => ({
  isTimerVisible: state.main.isTimerVisible,
  data: state.main.data,
  selectedConstruction: state.main.selectedConstruction,
  login: state.login
});

const mapDispatchToProps = {
  fetchData: fetchData,
  loginUser: loginUser,
  removeToken: removeToken
};

export default connect( 
  mapStateToProps, 
  mapDispatchToProps
)( withCookies(NavodyScreen) );
