import React from 'react';
import { StyledSelectWrapper } from './SelectStyles';
import Select from 'react-select';

const style1 = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#000' : '#000',
    background: state.isSelected ? '#E9E9F0' : 'white',
    margin: '0 !important'
  }),
  control: (base, state) => ({
    ...base,
    border: '1px solid #ddd !important',
    boxShadow: '0 !important',
    '&:hover': {
      border: '1px solid #ddd !important'
    },
    width: 250
  })
};

const style2 = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#43425D',
    margin: '0 !important'
  }),
  control: (base, state) => ({
    ...base,
    border: '1px solid #ddd !important',
    boxShadow: '0 !important',
    '&:hover': {
      border: '1px solid #ddd !important'
    },
    width: 200,
    fontSize: 14
  })
};

// select component used in menu component
const StyledSelect = ({
  defaultValue,
  options,
  placeholder,
  onChange,
  windowWidth
}) => (
  <StyledSelectWrapper>
    <Select
      isSearchable={false}
      options={options}
      placeholder={placeholder}
      styles={windowWidth < 426 ? style2 : style1}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  </StyledSelectWrapper>
);

export default StyledSelect;
