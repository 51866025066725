import React, { useEffect } from 'react';
import Menu from '../components/menu/Menu';
import { DashboardWrapper } from '../components/basic/BasicStyles';
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../actions/MainActions';

const PriceOfferScreen = ({ children, code }) => {

  // const code = this.props.code
  const dispatch = useDispatch()
  useEffect(() => {
    
    const options = {
      method: 'GET'
    }
    const url = `/api/usik/code/${code}`
    
    dispatch( fetchData( url, options ) )

  }, []);

  return (
    <DashboardWrapper>
      <Menu isPriceOffer={true} />
      {children}
    </DashboardWrapper>
  )

};

export default PriceOfferScreen;