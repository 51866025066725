import React, { Component } from 'react';
import HorizontalLayout2 from '../components/horizontal-layout2/HorizontalLayout2';
import ValidationForm from '../components/form/Form';
import FormInput from '../components/form/FormInput';
import { FormInputWrapper } from '../components/form/FormInputStyles';
import { isEmailValid } from '../utils/utils';
import { confirmAlert } from 'react-confirm-alert';

class ForgotPasswordScreen extends Component {
  
  state = {
    email: '',
    isEmail: false,
    isError: false
  };

  recoverPassword = async email => {
    const USIK_URL = process.env.REACT_APP_USIK_URL
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify( {email: email} )
    }
    const response = await fetch( `${USIK_URL}/api/send-email-reset-password?email=${email}`, options );

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const message = await response.json();
    return message
  }

  handleEmailInput = e => {
    this.setState({
      email: e.target.value,
      isEmail: isEmailValid(e.target.value)
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { isEmail, email } = this.state;
    if (isEmail) {
      await this.recoverPassword( email )
      confirmAlert({
        title: 'Email odoslaný',
        message: 'Na vašu e-mailovú adresu sme Vám poslali inštrukcie ako postupovať.',
        buttons: [
          {
            label: 'OK',
            onClick: () => window.location.reload()
          }
        ]
      });
    } else {
      console.error('FORM INVALID -DISPLAY ERROR MESSAGE');
    }
  };

  render() {
    const { isEmail } = this.state;
    return (
      <div>
        <HorizontalLayout2>
          <ValidationForm
            formTitle='STRATA HESLA'
            formAbout='Zadajte e-mail na ktorý ste dostali identifikačný kód'
            isPersonalInfoVisible={false}
            submitButtonText='Odoslať nové heslo'
            isAlreadyUser='false'
            alreadyUserHref=''
            onSubmit={this.handleSubmit}
            disabled={!isEmail}
          >
            <FormInputWrapper>
              <FormInput
                placeholder='E-mail'
                type='email'
                required={true}
                onChange={e => this.handleEmailInput(e)}
              />
            </FormInputWrapper>
          </ValidationForm>
        </HorizontalLayout2>
      </div>
    );
  }
}

export default ForgotPasswordScreen;
