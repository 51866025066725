import { REGISTER } from './Types';


const processData = async ( url, options ) => {
  
  const USIK_URL = process.env.REACT_APP_USIK_URL
  const res = await fetch( `${USIK_URL}${url}`, options );
  
  if( res.ok ) {
    const json = await res.json();
    return json;
  } else {
    return false
  }
};

export const registerUser = (data, token) => async dispatch => {

  // This is sample payload
  let payload = {
    data,
    error: false
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify( data )
  }

  let req = await processData( `/api/recover-password?token=${token}`, options )

  if ( req === false ) {
    payload = {
      data: data,
      error: true
    };
  }

  dispatch({ type: REGISTER, payload: payload });
};
