import React from 'react';
import {
  NavodyBasketWrapperResp,
  NavodyBasketContentResp,
  NavodyBasketHiddenContent,
  NavodyBasketHiddenContentItem
} from './NavodyBasketStyles';
import NavodyBasketItem from './NavodyBasketItem';
import NavodyBasketButton from './NavodyBasketSubmitButton';
import ArrowButton from '../../arrow-button/ArrowButton';
import { ArrowButtonContent } from '../../arrow-button/ArrowButtonStyles';

const NavodyBasketHidden = ({
  originalPriceValue,
  saveValue,
  newPriceValue,
  onSubmitButtonClick,
  onBasketArrowClick,
  isBasketVisible
}) => (
  <NavodyBasketWrapperResp>
    <NavodyBasketContentResp>
      <NavodyBasketItem
        isbold='true'
        value={newPriceValue}
        renderText={newPriceValue}
        labelText='Predpokladaná nová cenová ponuka'
      />
      {/* handle arrow visibilty in basket if window width is less than 768px */}
      {!isBasketVisible && (
        <ArrowButtonContent
          // on click handle basket visibility
          onClick={onBasketArrowClick}
          isBasketVisible={!isBasketVisible}
        >
          <ArrowButton isBasket isArrowUp isStatic />
        </ArrowButtonContent>
      )}
      {/* Content is hidden if isBaskeVisible props === false */}
      <NavodyBasketHiddenContent isBasketVisible={isBasketVisible}>
        <NavodyBasketHiddenContentItem>
          <NavodyBasketItem
            value={originalPriceValue}
            renderText={originalPriceValue}
            labelText='Pôvodná cenová ponuka'
          />
          <ArrowButtonContent
            onClick={onBasketArrowClick}
            isBasketVisible={isBasketVisible}
          >
            <ArrowButton isBasket isStatic />
          </ArrowButtonContent>
        </NavodyBasketHiddenContentItem>

        <NavodyBasketHiddenContentItem>
          <NavodyBasketItem
            value={saveValue}
            renderText={saveValue}
            prefix={'-'}
            labelText='Ušetríte'
          />
        </NavodyBasketHiddenContentItem>
      </NavodyBasketHiddenContent>
    </NavodyBasketContentResp>
    <NavodyBasketButton
      onClick={onSubmitButtonClick}
      buttonText='Odoslať cenovú ponuku'
    />
  </NavodyBasketWrapperResp>
);

export default NavodyBasketHidden;
