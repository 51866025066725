import styled from 'styled-components';
import { Link } from '@reach/router';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;
`;

export const FormTitle = styled.h1`
  letter-spacing: 5px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.neutral.purple};
  margin: 0;
  font-size: 35px;
  transition: font-size 0.2s ease-in-out;
  @media (max-width: 768px) {
    font-size: 32px;
  }
  @media (max-width: 425px) {
    font-size: 30px;
  }
`;

export const FormAbout = styled.h3`
  font-weight: 400;
  color: ${({ theme }) => theme.neutral.purple};
  opacity: 0.5;
  margin: 0;
  margin-top: 10px;
  text-align: center;
  transition: font-size 0.2s ease-in-out;

  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 15px;
  }
`;

export const FormContentWrapper = styled.div`
  width: 100%;
`;

export const PersonalInfoProcessing = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  margin-top: 40px;
  cursor: pointer;
  width: 100%;
  align-items: center;
`;

export const ForgotPassword = styled(Link)`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  color: ${({ theme }) => theme.neutral.purple};
  text-decoration: none;
  font-weight: 600;
  width: 100%;
  text-align: right;
  margin-top: 40px;
  transition: font-size 0.2s ease-in-out;

  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 15px;
  }
`;
export const FormSubmitButton = styled.button`
  background-color: ${({ theme }) => theme.neutral.purple};
  border: 2px solid ${({ theme }) => theme.neutral.purple};
  color: ${({ theme }) => theme.light.white};
  width: 100%;
  margin: 0;
  height: 50px;
  padding-top: 2px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 3px;
  margin-top: 40px;
  transition-property: color, background-color, border-radius, font-size;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.neutral.purple};
    border-radius: 0;
  }
  &:disabled {
    opacity: 0.5;
    background-color: ${({ theme }) => theme.neutral.purple};
    color: ${({ theme }) => theme.light.white};
  }
  &:disabled:hover {
    
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 15px;
  }
`;

export const AlreadyUser = styled(Link)`
  color: ${({ theme }) => theme.neutral.purple};
  font-weight: 600;
  margin-top: 40px;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  transition: font-size 0.2s ease-in-out;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 15px;
  }
`;
