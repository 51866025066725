import React from 'react';
import './Button.css';
// import styled from 'styled-components';

const Button = ({ type, handleClick, id, data, previous_done_at }) => {

    const { enable_diy, diy_selected, done_at, need_material, material_sent } = data
    let text = ""
    let classes = ""
    let disabled = true

    // Cakajte na dodanie materialu
    if ( diy_selected === true && need_material === true && material_sent == null && ( typeof previous_done_at === "string" || previous_done_at === false ) ) {
        text = "Čakajte na dodanie materiálu"
        classes = "btn btn-dashboard btn-dashboard-wait btn-dashboard-material"
    }

    // Dokončiť krok ( predosly krok nie je dokonceny )
    else if( diy_selected === true && done_at === null && ( typeof previous_done_at === "string" || previous_done_at === false ) ) {
        text = "Dokončiť krok"
        classes = "btn btn-dashboard btn-dashboard-finish"
        disabled = false
    }

    else if( diy_selected === false && (enable_diy === false || enable_diy === true) && done_at === null && previous_done_at === false ) {
        text = "Počkajte kým krok dokončíme"
        classes = "btn btn-dashboard btn-dashboard-wait btn-dashboard-wait-inprogress"
    }
    
    // Počkajte na dokončenie predošlého kroku
    else if( diy_selected === true  && done_at === null && ( previous_done_at === null && previous_done_at !== false ) ) {
        text = "Počkajte na dokončenie predošlého kroku"
        classes = "btn btn-dashboard btn-dashboard-wait"
    }

    // Počkajte kým krok dokončíme
    else if( (enable_diy === false || diy_selected === false ) && done_at === null && ( typeof previous_done_at === "string" ) ) {
        text = "Počkajte kým krok dokončíme"
        classes = "btn btn-dashboard btn-dashboard-wait btn-dashboard-wait-inprogress"
    }

    // Počkajte na dokončenie predošlého kroku a ozveme sa Vám
    else if( (enable_diy === false || diy_selected === false ) && done_at === null && previous_done_at === null ) {
        text = "Počkajte na dokončenie predošlého kroku a ozveme sa Vám"
        classes = "btn btn-dashboard btn-dashboard-wait btn-dashboard-wait-beforeprogress"
    }

    // Dokončené
    else if( done_at != null ) {
        text = "Dokončené"
        classes = "btn btn-dashboard btn-dashboard-finished"
    }
    
    return (
        <button 
            type={type}
            disabled={disabled} 
            className={classes} 
            onClick={( event ) => handleClick( event, id )}
        >
            {text}
        </button>
    )
}

export default Button