import styled from 'styled-components';

export const NavodyHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  // @media (max-width: 768px) {
  //   display: none;
  // }
`;

// LEFT
export const NavodyHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavodyHeaderTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;

  span {
    display: inline-block;
    font-size: 2em;
    border-bottom: 2px solid ${({ theme }) => theme.light.gray};
    color: ${({ theme }) => theme.neutral.purple};
    @media (max-width: 424px) {
      width: 95%;
      margin: 0 auto;
      font-size: 1.7em;
      margin-left: 0.5em;
    }
  }
  input {
    max-width: 300px;
    padding: 0;
    margin: 0;
    font-size: 2em;
    border: 0;
    outline: 0;
    height: 100%;
    color: ${({ theme }) => theme.dark.gray};
    border-bottom: 2px solid ${({ theme }) => theme.dark.gray};
    background-color: ${({ theme }) => theme.light.grayDash};
  }
`;

export const NavodyHeaderSvgWrapper = styled.div`
  posititon: relative;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ isTitleEditable }) =>
    isTitleEditable
      ? ({ theme }) => theme.dark.gray
      : ({ theme }) => theme.light.grayDash};
`;

export const NavodyHeaderTokenWrapper = styled.div`
  margin-top: .5em;
  display: flex;
  color: ${({ theme }) => theme.dark.gray};
  font-weight: 600;
  font-size: 14px;
  opacity: 0.66;
  @media (max-width: 768px) {
    margin-top: ${({ isPriceOffer }) => ( isPriceOffer ? "0em" : "0.5em" )};
    display: flex;
    border: 1px solid ${({ theme }) => theme.light.purple};
    border-radius: 5px;
    padding: 0.75em 1.5em;
    background-color: rgba(163, 160, 251, 0.15);
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 424px) {
    width: 95%;
    margin: 0 auto;
    margin-top: 0.5em;
  }
  svg {
    cursor: pointer;
  }
`;
export const NavodyHeaderToken = styled.div`
  padding-right: 0.5em;
`;

// RIGHT
export const NavodyHeaderRight = styled.div`
  color: ${({ theme }) => theme.dark.gray};

  h3 {
    font-weight: 700;
    text-align: right;
    margin: 0;
    color: ${({ theme }) => theme.neutral.purple};

    input {
      text-align: right !important;
      border: 0;
      background-color: ${({ theme }) => theme.light.grayDash};
      outline: 0;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
  div {
    font-weight: 600;
    font-size: 14px;
    opacity: 0.66;
    text-align: right;
  }
`;
