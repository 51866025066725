import React, { Component } from 'react';
import HorizontalLayout2 from '../components/horizontal-layout2/HorizontalLayout2';
import ValidationForm from '../components/form/Form';
import FormInput from '../components/form/FormInput';
import {
  FormInputWrapper,
  FormInputBlankSpace,
  ErrorMessage
} from '../components/form/FormInputStyles';
import {
  formValid,
  isEmailValid,
  isPasswordValid
} from '../utils/utils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { registerUser } from '../actions/RegisterActions';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';

class RegistrationScreen extends Component {

  state = {
    email: '',
    password: '',
    confirmPassword: '',
    token: null,
    formErrors: {
      emailErr: '',
      passwordErr: '',
      confirmPasswordErr: ''
    }
  };

  componentDidMount() {

    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token')
    const email = query.get('email')

    if( email ) {
      this.setState({
        email,
        token
      })
    }

  }

  handleCheckboxInput = e => {
    this.setState({ isChecked: e });
  };

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    this.setState({ [name]: value });

    switch (name) {
      case 'email':
        formErrors.emailErr =
          isEmailValid(value) || value.length === 0
            ? ''
            : 'Zadaný email má chybný formát.';
        break;
      case 'password':
        formErrors.passwordErr =
          isPasswordValid(value) || value.length === 0
            ? ''
            : 'Predvolená minimálna dĺžka hesla je 8 znakov. Zadané heslo musí obsahovať aspoň jedno veľké písmeno, jedno malé písmeno,  jeden číselný znak a žiadne špeciálne znaky (.,_).';
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    
    const {
      token,
      email,
      password,
      confirmPassword
    } = this.state;

    const payload = {
      email,
      password
    }

    if (formValid(this.state) && password === confirmPassword) {
      this.props.registerUser( payload, token);
    } else if (password !== confirmPassword) {
      
      this.setState(
        { confirmPasswordErr: 'Zadané heslá sa nezhodujú.' },
        () => {
          // confirm alert window
          confirmAlert({
            title: this.state.confirmPasswordErr,
            message:
              'Predvolená minimálna dĺžka hesla je 8 znakov. Zadané heslo musí obsahovať aspoň jedno veľké písmeno, jedno malé písmeno,  jeden číselný znak a žiadne špeciálne znaky (.,_).',
            buttons: [
              {
                label: 'OK',
                onClick: this.setState({ password: '', confirmPassword: '' })
              }
            ]
          });
        }
      );

    } else {
      console.error('FORM INVALID -DISPLAY ERROR MESSAGE');
    }
  };

  render() {
    const {
      email,
      password,
      confirmPassword,
      formErrors,
      isChecked
    } = this.state;

    const { confirmPasswordErr } = this.state.formErrors;
    const { data, error } = this.props.register;

    if (error) {
      return <Redirect noThrow to='/error/Registrácia neprebehla úspešne' />;
    }

    if (data) {
      return <Redirect noThrow to='/registration-success' />;
    }

    return (
      <div>
        <HorizontalLayout2>
          <ValidationForm
            formTitle='REGISTRÁCIA'
            formAbout='Vytvorte si účet pre používanie portálu'
            isPersonalInfoVisible='true'
            submitButtonText='Registrovať'
            isAlreadyUser='false'
            alreadyUserHref='/login'
            alreadyUserText='Máte už konto? Prihláste sa'
            onSubmit={this.handleSubmit}
            disabled={!formValid(this.state)}
            onCheckboxChange={e => this.handleCheckboxInput(e)}
            checked={isChecked}
            htmlForCheckbox='registration-checkbox'
            checkboxName='registration-checkbox'
          >
            <FormInputWrapper>
              <FormInput
                placeholder='Email (predvyplnené ak to bude možné)'
                value={email === '' ? '' : email}
                type='email'
                required={true}
                onChange={e => this.handleChange(e)}
                name='email'
                className={
                  formErrors.emailErr.length > 0 ? 'input-error' : null
                }
              />
            </FormInputWrapper>
            {!isEmailValid(email) && (
              <ErrorMessage>{formErrors.emailErr}</ErrorMessage>
            )}
            <FormInputWrapper>
              <FormInput
                placeholder='Heslo'
                type='password'
                required={true}
                onChange={e => this.handleChange(e)}
                name='password'
                value={password}
                className={
                  formErrors.passwordErr.length > 0 ? 'input-error' : null
                }
              />
            </FormInputWrapper>
            {!isPasswordValid(password) && (
              <ErrorMessage>{formErrors.passwordErr}</ErrorMessage>
            )}
            <FormInputWrapper>
              <FormInput
                placeholder='Zopakujte heslo'
                type='password'
                required={true}
                onChange={e => this.handleChange(e)}
                name='confirmPassword'
                value={confirmPassword}
              />
            </FormInputWrapper>
            {confirmPasswordErr.length > 0 && (
              <ErrorMessage>{this.state.confirmPasswordErr}</ErrorMessage>
            )}
          </ValidationForm>
        </HorizontalLayout2>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  register: state.register
});

const mapDispatchToProps = {
  registerUser: registerUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationScreen);
