import React from 'react';
import { NavodyBasketWrapper } from './NavodyBasketStyles';
import NavodyBasketItem from './NavodyBasketItem';
import NavodyBasketButton from './NavodyBasketSubmitButton';
import NavodyBasketHidden from './NavodyBasketHidden';

const NavodyBasketWrapperComponent = ({
  originalPriceValue,
  saveValue,
  newPriceValue,
  onSubmitButtonClick,
  windowWidth,
  onBasketArrowClick,
  isBasketVisible
}) => (
  <div>
    <NavodyBasketWrapper>
      <NavodyBasketItem
        value={originalPriceValue}
        renderText={originalPriceValue}
        labelText='Pôvodná cena práce'
      />
      <NavodyBasketItem
        value={saveValue}
        renderText={saveValue}
        prefix={'-'}
        labelText='Ušetríte'
      />
      <NavodyBasketItem
        isbold='true'
        value={newPriceValue}
        renderText={newPriceValue}
        labelText='Predpokladaná nová cena práce'
      />
      <NavodyBasketButton
        onClick={onSubmitButtonClick}
        buttonText={
          windowWidth < 1025
            ? 'Odoslať cenovú ponuku'
            : 'Odoslať aktualizovanú cenu práce'
        }
      />
    </NavodyBasketWrapper>
    {/* component is visible if window width is less than 768px */}
    <NavodyBasketHidden
      originalPriceValue={originalPriceValue}
      saveValue={saveValue}
      newPriceValue={newPriceValue}
      onSubmitButtonClick={onSubmitButtonClick}
      onBasketArrowClick={onBasketArrowClick}
      isBasketVisible={isBasketVisible}
    />
  </div>
);

export default NavodyBasketWrapperComponent;
