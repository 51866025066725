import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import theme from './styles/themes/themes';

import './App.css';
import routes from './routes';
import store from './store';
import { CookiesProvider } from 'react-cookie';

class App extends React.Component {
  render() {
    return (
      <CookiesProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>{routes}</ThemeProvider>
        </Provider>
      </CookiesProvider>
    );
  }
}

export default App;
