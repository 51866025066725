// round number
export const fixRound = number => {
  return Math.round(number * 100) / 100;
};

export const getOriginalPriceOffer = data => {
  return data.reduce((acc, currentValue) => {
    let replaceValue = currentValue.assemble_price.toFixed(2).replace(',', '.');
    return fixRound(acc + JSON.parse(replaceValue));
  }, 0.0);
};

export const handleBasketItems = steps => {
  return steps.reduce((acc, { id, assemble_price }) => {
    let price
    
    if( typeof assemble_price === "string" ) {
      price = parseInt(assemble_price).toFixed(2).replace(',', '.')
    } else {
      price = assemble_price.toFixed(2).replace(',', '.')
    }

    return {
      ...acc,
      [id]: {
        id: id,
        assemble_price: JSON.parse( price ),
        diy_selected: false
      }
    };
  }, {});
};

export const handleSavePrice = basket => {
  return Object.values(basket).reduce((acc, { assemble_price,  diy_selected  }) => {
    if ( diy_selected ) {
      return fixRound(acc + assemble_price);
    }
    return fixRound(acc);
  }, 0.0);
};

export const normalizeData = data => {
  return data.map((item, index) => {
    if (data.length - 1 === index) {
      return { ...item, diy_selected: false, isLastItem: true };
    }
    return { ...item, diy_selected: false, isLastItem: false };
  });
};

export const processData = async ( url, options ) => {
  const USIK_URL = process.env.REACT_APP_USIK_URL
  const res = await fetch( `${USIK_URL}${url}`, options );
  
  if( res.ok ) {
    const json = await res.json();
    return json;
  } else {
    return res
  }
};