import React, { Component } from 'react';
import {
  UserWrapper,
  UserName,
  UserImage,
  UserImageWrapper,
  UserNameWrapper,
  UserManagment,
  LogoutButton,
  UserManagmentItem,
  UserNameResp
} from './UserProfileStyles';
import Avatar from '../../assets/profile/avatar.jpg';

const rotateArrow = { transform: 'rotate(180deg)' };

class UserProfile extends Component {
  state = { isProfileOpen: false };

  // if clicked outside, close popup
  handleClick = () => {
    if (!this.state.isProfileOpen) {
      document.addEventListener('click', this.handleOutsideClickUser, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClickUser, false);
    }

    this.setState(prevState => ({
      isProfileOpen: !prevState.isProfileOpen
    }));
  };

  handleOutsideClickUser = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  };

  render() {
    const { logoutButtonClick, children, data } = this.props;
    const { isProfileOpen } = this.state;

    return (
      <UserWrapper id={data.id} ref={node => (this.node = node)}>
        {children}
        <UserNameWrapper onClick={this.handleClick}>
          <UserName>
            {data.name}
          </UserName>
          <svg
            id='small-down'
            xmlns='http://www.w3.org/2000/svg'
            width='11'
            height='7'
            viewBox='0 0 11 7'
            style={isProfileOpen ? rotateArrow : null}
          >
            <path
              id='Path_26'
              data-name='Path 26'
              d='M8.1,11.6,2.6,6.041,4.026,4.6,8.1,8.718,12.174,4.6,13.6,6.041Z'
              transform='translate(-2.6 -4.6)'
            />
          </svg>
          {/* <UserImageWrapper>
            <UserImage src={Avatar} />
          </UserImageWrapper> */}
        </UserNameWrapper>

        {isProfileOpen && (
          <UserManagment isProfileOpen={isProfileOpen}>
            <UserManagmentItem>
              <UserNameResp>
                {data.name}
              </UserNameResp>
            </UserManagmentItem>
            <UserManagmentItem>
              <LogoutButton onClick={() => logoutButtonClick()}>Odhlásiť</LogoutButton>
            </UserManagmentItem>
          </UserManagment>
        )}
      </UserWrapper>
    );
  }
}

export default UserProfile;
