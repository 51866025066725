import { VALIDATE_TOKEN } from '../actions/Types';
import { LOADING_TOKEN_START } from '../actions/Types';
import { LOADING_TOKEN_END } from '../actions/Types';

const initialState = {
  isTokenValid: false,
  isLoading: true,
  user: {
    id: null,
    name: null,
    email: null
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VALIDATE_TOKEN:
      return {
        ...state,
        isTokenValid: action.payload.isTokenValid,
        user: action.payload.user
      };
    case LOADING_TOKEN_START:
      return {
        ...state,
        isLoading: true 
      };
    case LOADING_TOKEN_END:
      return {
        ...state,
        isLoading: false 
      };
    default:
      return state;
  }
}
