import React from 'react';
import {
  StyledForm,
  FormTitle,
  FormAbout,
  FormContentWrapper,
  PersonalInfoProcessing,
  FormSubmitButton,
  AlreadyUser,
  ForgotPassword
} from './FormStyles';
import Checkbox from '../checkbox/Checkbox';

const Form = ({
  formTitle,
  formAbout,
  isPersonalInfoVisible,
  isForgotPasswordVisible,
  children,
  submitButtonText,
  isAlreadyUser,
  alreadyUserHref,
  alreadyUserText,
  onSubmit,
  onCheckboxChange,
  disabled,
  checked,
  checkboxName,
  htmlForCheckbox
}) => (
  // one component for validation, registration, login and forgot password screen - depends on props
  <StyledForm onSubmit={onSubmit}>
    <FormTitle>{formTitle}</FormTitle>
    <FormAbout>{formAbout}</FormAbout>
    {/* Children props for inputs */}
    <FormContentWrapper>{children}</FormContentWrapper>

    {/* REGISTRATION SCREEN */}
    <PersonalInfoProcessing visible={isPersonalInfoVisible}>
      {/* Custom checkbox component */}
      {/* <Checkbox
        name={checkboxName}
        htmlFor={htmlForCheckbox}
        onChange={onCheckboxChange}
        checked={checked}
        label='Súhlasim s podmienkami a spracovaním osobných údajov'
      /> */}
    </PersonalInfoProcessing>
    {/* FORGOT PASSWORD SCREEN */}
    <ForgotPassword visible={isForgotPasswordVisible} to='/forgotPassword'>
      Zabudol som heslo
    </ForgotPassword>
    <FormSubmitButton 
      // onClick={() => gtag('event', 'registration', {'event_category': 'form'})} 
      disabled={disabled}>{submitButtonText}</FormSubmitButton>
    {/* LOGIN SCREEN */}
    <AlreadyUser visible={isAlreadyUser} to={alreadyUserHref}>
      {alreadyUserText}
    </AlreadyUser>
  </StyledForm>
);

export default Form;
