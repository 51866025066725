import React, { Component } from 'react';
import styled from 'styled-components';
import {
  NavodyItemWrapper,
  NavodyItemHeader,
  NavodyItemHeaderLeft,
  NavodyItemHeaderOrderNo,
  NavodyItemHeaderTitle,
  NavodyItemHeaderRight,
  NavodyItemHeaderButtonWrapper,
  NavodyItemHeaderPriceWrapper,
  NavodyItemHeaderButton,
  NavodyItemHeaderArrow,
  NavodyItemBody,
  NavodyItemBodyContent,
  NavodyItemBodyTitle,
  NavodyItemBodySubTitle,
  NavodyItemBodyText,
  NavodyItemBodyPlayer,
  NavodyItemFooter,
  NavodyItemNumberFormat
} from './NavodyItemStyles';
import Rating from '../rating/Rating';
import Button from '../../button/Button';
import SelectedAction from '../../button/SelectedAction';
import { Collapse } from 'react-bootstrap';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import ArrowButton from '../../arrow-button/ArrowButton';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../../../actions/MainActions';
import { confirmAlert } from 'react-confirm-alert';

const Item = styled.div`
  margin: 0 auto;
  width: 100%;
`
const ItemHeader = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
background-color: white;
padding-left: 0.75em;

@media (max-width: 678px) {
  flex-direction: column;
}

> div {
  display: flex;
  align-items: center;
}
`
const ItemHeaderLeft = styled.div`
  justify-content: flex-start;
  width: 35%;
  @media (max-width: 678px) {
    width: 100%;
    padding-top: 0.5em;
  }
`
const ItemHeaderRight = styled.div`
  justify-content: flex-end;
  width: 65%;
  @media (max-width: 678px) {
    width: 100%;
  }
`
const ButtonStyled = styled.button`
outline: none;
border: none;

// width: 40%;
// max-width: 270px;

font-size: 13px;
font-weight: bold;
color: #3689FF;
text-align: center;

background-color: white;
padding: 0.75em 2em;
margin-right: 1em;

border: 2px solid #3589FF;
border-radius: 4px;
`;

// class NavodyItemDesktop extends Component {
//   // onFormChange handle values 'spravim'/'nespravim' and send id, value(true/false) and price in addToBasket function as a props
//   onFormChange = e => {
//     const { addToBasket } = this.props;
//     addToBasket(
//       JSON.parse(e.target.value),
//       this.props.id,
//       JSON.parse(this.props.price.toFixed(2).replace(',', '.'))
//     );
//   };

//   render() {
//     const {
//       isCollapsed,
//       stepNumber,
//       title,
//       name,
//       id,
//       price,
//       videoURL,
//       description,
//       isLastItem,
//       responsible,
//       isResponsible,
//       clickOnCollapse,
//       windowWidth,
//       isPriceOffer
//     } = this.props;

//     
//   }
// }


const NavodyItemDesktop = ({
  addToBasket,
  isCollapsed,
  stepNumber,
  title,
  name,
  id,
  price,
  videoURL,
  description,
  isLastItem,
  responsible,
  isResponsible,
  clickOnCollapse,
  windowWidth,
  isPriceOffer,
  storyId
}) => {

  const dispatch = useDispatch()
  const selectedConstruction = useSelector( state => state.main.selectedConstruction )
  const data = useSelector( state => state.main.data )
  
  //   // onFormChange handle values 'spravim'/'nespravim' and send id, value(true/false) and price in addToBasket function as a props
  const onFormChange = e => {
    addToBasket(
      JSON.parse(e.target.value),
      id,
      JSON.parse(price.toFixed(2).replace(',', '.'))
    );
  };

  const processData = async ( url, options ) => {
    const USIK_URL = process.env.REACT_APP_USIK_URL
    const res = await fetch( `${USIK_URL}${url}`, options );
    
    if( res.ok ) {
      const json = await res.json();
      return json;
    } else {
      return res
    }
  };

  const getTasks = () => {
    let steps = []
    if(!isPriceOffer){
      let story = data[selectedConstruction].stories.filter( story => story.id === storyId )
      if(  story[0] != null ) {
        story[0].tasks.forEach( task => steps = [ ...steps, task ] )
      } 
    }
    else {
      data[selectedConstruction].stories.forEach( story => { 
        story.tasks.forEach( task => steps = [ ...steps, task ] )
      })
    }
    return steps
  }

  // get data from current item
  const getItem = ( id ) => {
    
    let steps = getTasks()
    let current = steps.filter( step => step.id === id )
    
    if ( current.length === 1 ) {
      const enable_diy = current[0].enable_diy
      const diy_selected = current[0].diy_selected
      const done_at = current[0].done_at
      const need_material = current[0].need_material
      const material_sent = current[0].material_sent

      let data = {
        enable_diy,
        diy_selected,
        done_at,
        need_material,
        material_sent
      }

      return data
    }
    return false
  }
  // get data from previous item
  const getPreviousItem = ( id ) => {
    let steps = getTasks()
    let previous = steps.filter( step => step.id === id - 1 )

    return previous.length === 1 ? previous[0].done_at : false
  }

  const markTaskAsDone = async ( token, tasks ) => {
    // send tasks to backend
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${ token  || null }`
      },
      body: JSON.stringify( tasks )
    } 
    await processData( `/api/task-done`, options )
    
    const options2 = {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${ token || null }`
      }
    }
    await dispatch( fetchData( `/api/usik/index`, options2, selectedConstruction ) )

  }

  const onButtonClick = async ( event, id ) => {

    event.preventDefault()
    const token = localStorage.getItem('token')
    const tasks = { 
      tasks: [id]
    }

    confirmAlert({
      message: 'Naozaj chcete dokončiť úlohu? Tento krok je nenávratný.',
      buttons: [
        {
          label: 'OK',
          onClick: () => markTaskAsDone( token, tasks )
        },
        {
          label: 'Zrušiť'
        }
      ]
    })
    
  }

  return isPriceOffer ? (
    // id is neccessary if window width is less than 769px and is clicked on component, id is sent to modal window where is rendered info about navody item with this id
    <NavodyItemWrapper id={`item${id}`}>
    
      <NavodyItemHeader>
        <NavodyItemHeaderLeft>
          <NavodyItemHeaderOrderNo>{stepNumber}</NavodyItemHeaderOrderNo>
          <NavodyItemHeaderTitle isPriceOffer={isPriceOffer}>
            <h6>{title}</h6>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {windowWidth < 769 && (
                <NavodyItemHeaderPriceWrapper>
                  <div>Cena kroku</div>
                  <span>
                    <NavodyItemNumberFormat
                      alignleft={true.toString()}
                      decimalSeparator={','}
                      suffix={'€'}
                      thousandSeparator={'.'}
                      value={price}
                      renderText={value => <div>{price}</div>}
                    />
                  </span>
                </NavodyItemHeaderPriceWrapper>
              )}
            </div>
          </NavodyItemHeaderTitle>
        </NavodyItemHeaderLeft>
        <NavodyItemHeaderRight>
          {/* if isResponsible value === false input is disabled */}
          { isResponsible === false ? (
            <NavodyItemHeaderButtonWrapper>
              <NavodyItemHeaderButton isDisabled={true}>
                <input
                  checked={true}
                  id={`zasah${id}`}
                  name={name}
                  type='radio'
                  disabled
                />
                <label htmlFor={`zasah${id}`}>
                  Zabezpečujeme potrebný odborný zásah
                </label>
              </NavodyItemHeaderButton>
            </NavodyItemHeaderButtonWrapper>
          ) : (
            <NavodyItemHeaderButtonWrapper onChange={(event) => onFormChange(event)}>
              <NavodyItemHeaderButton>
                <input
                  defaultChecked={!responsible}
                  id={`necham${id}`}
                  name={name}
                  type='radio'
                  value={false}
                />
                <label htmlFor={`necham${id}`}>Nechám si to spraviť</label>
              </NavodyItemHeaderButton>
              <NavodyItemHeaderButton>
                <input
                  defaultChecked={responsible}
                  id={`spravim${id}`}
                  name={name}
                  type='radio'
                  value={true}
                />
                <label htmlFor={`spravim${id}`}>Spravím si to sám</label>
              </NavodyItemHeaderButton>
            </NavodyItemHeaderButtonWrapper>
          )}
          {windowWidth > 768 && isPriceOffer && (
            <NavodyItemHeaderPriceWrapper>
              <div>Cena kroku</div>
              <span>
                <NavodyItemNumberFormat
                  decimalSeparator={','}
                  suffix={'€'}
                  thousandSeparator={'.'}
                  value={price}
                  renderText={value => <div>{price}</div>}
                />
              </span>
            </NavodyItemHeaderPriceWrapper>
          )}

          <NavodyItemHeaderArrow
            // on click handle collapse visibility
            onClick={clickOnCollapse}
            aria-expanded={isCollapsed}
          >
            {windowWidth < 769 ? (
              // on anchor click scroll to element
              <AnchorLink href={`#item${id}`}>
                <ArrowButton isStatic />
              </AnchorLink>
            ) : (
              <AnchorLink offset='20' href={`#item${id}`}>
                <ArrowButton />
              </AnchorLink>
            )}
          </NavodyItemHeaderArrow>
        </NavodyItemHeaderRight>
      </NavodyItemHeader>
      
      <Collapse in={windowWidth < 769 ? null : isCollapsed}>
        <NavodyItemBody>
        <NavodyItemBodyPlayer>
        <Video
              autoPlay={false}
              loop={false}
              muted={false}
              controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
              controlsList={'nodownload'}
            >
              <source src={videoURL} type='video/mp4' />
            </Video>
        </NavodyItemBodyPlayer>
        <NavodyItemBodyContent>
          <NavodyItemBodyTitle>{title}</NavodyItemBodyTitle>
          <NavodyItemBodySubTitle>
            Popis inštruktážného videa
          </NavodyItemBodySubTitle>
          <NavodyItemBodyText>{description}</NavodyItemBodyText>
        </NavodyItemBodyContent>
      </NavodyItemBody>
      </Collapse>
    
      {!isLastItem && (
        <NavodyItemFooter>
          <span />
        </NavodyItemFooter>
      )}
    </NavodyItemWrapper>
  ) : (
    <Item>
      <ItemHeader>
        <ItemHeaderLeft>
          <NavodyItemHeaderOrderNo>{stepNumber}</NavodyItemHeaderOrderNo>
          <NavodyItemHeaderTitle>
            <h6 style={{ fontSize: "16px" }}>{title}</h6>
          </NavodyItemHeaderTitle>
          <SelectedAction visible={false} type="submit" disabled={true} data={getItem(id)} />
        </ItemHeaderLeft>
        <ItemHeaderRight>
          
          {/* <Button type="submit" isDisabled={true} enable_diy={true} diy_selected={true} done_at={null} /> */}
          
          <SelectedAction type="submit" disabled={true} data={getItem(id)} />
          
          <Button type="button" id={id} handleClick={onButtonClick} data={getItem(id)} previous_done_at={getPreviousItem(id)} />

        </ItemHeaderRight>
      </ItemHeader>
      
      {/* <div className="item-content">Content</div> */}

      {/* <Collapse in={windowWidth < 769 ? null : isCollapsed}> */}
        <NavodyItemBody>
          <NavodyItemBodyPlayer>
            <Video
              autoPlay={false}
              loop={false}
              muted={false}
              controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
              controlsList={'nodownload'}
              // crossOrigin={'use-credentials'}
            >
              <source src={videoURL} type='video/mp4' />
              Video could not be loaded
            </Video>
            {/* <video className="js-video-prevent-download"  width="306" height="auto" controls controlsList="nodownload">
              <source src={videoURL} type='video/mp4' />
            </video> */}
          </NavodyItemBodyPlayer>
          <NavodyItemBodyContent>
            <NavodyItemBodyTitle>{title}</NavodyItemBodyTitle>
            <NavodyItemBodySubTitle>
              Popis inštruktážného videa
            </NavodyItemBodySubTitle>
            <NavodyItemBodyText>{description}</NavodyItemBodyText>
          </NavodyItemBodyContent>
        </NavodyItemBody>
      {/* </Collapse> */}
        
    {!isLastItem && (
      <NavodyItemFooter>
        <span />
      </NavodyItemFooter>
    )}

    </Item>
  )
}

export default NavodyItemDesktop;
