import React, { Component } from 'react';
import NavodyItemWrapper from '../item/NavodyItemWrapper';
import {
  NavodyItemBodyTitle,
  NavodyItemBodySubTitle,
} from '../item/NavodyItemStyles'

const NavodyVideos = ({
  addToBasket,
  handleItemProps,
  windowWidth,
  data,
  stories,
  isTimerVisible,
  isPriceOffer,
  code,
  getVideoUrl
}) => {

  // const getVideoUrl = ( video, isPriceOffer ) => {
  //   const USIK_URL = process.env.REACT_APP_USIK_URL
  //   if( video != null ) {
  //     const token = localStorage.getItem('token')
  //     let apiUrl = isPriceOffer ? "/api/usik/video" : "/api/video/display"
  //     let query = isPriceOffer && `?video_id=${video.id}&code_id=${code}` || `?video_id=${video.id}&jwt-token=${token}`
  //     let src = `${USIK_URL}${apiUrl}${query}`
  //     return src
  //   }
  //   return ""
  // }

    return (
      <div>
        {stories.map((story, index) => {
          let {title, description, tasks} = story
          return (
            <div key={index}>
            <NavodyItemBodyTitle className='story-title' style={{paddingTop:'20px'}}>{title}</NavodyItemBodyTitle>
            <NavodyItemBodySubTitle>{description}</NavodyItemBodySubTitle>
            {tasks.map((task, index) => {
              let {title, description, id, assemble_price, video, enable_diy, diy_selected} = task
              
              if(tasks.length - 1 === index){
                return (
                  <NavodyItemWrapper
                    windowWidth={windowWidth}
                    handleItemProps={handleItemProps}
                    addToBasket={addToBasket}
                    stepNumber={ index + 1 }
                    title={title}
                    description={description}
                    name={id}
                    id={id}
                    price={typeof assemble_price === "string" ? parseInt(assemble_price) : assemble_price }
                    videoURL={getVideoUrl( video, isPriceOffer )}
                    isLastItem={true}
                    isResponsible={enable_diy}
                    responsible={diy_selected}
                    key={id}
                    data={data}
                    isTimerVisible={isTimerVisible}
                    isPriceOffer={isPriceOffer}
                    storyId={story.id}
                  />
                )
              }
              
              return (
                <NavodyItemWrapper
                  windowWidth={windowWidth}
                  handleItemProps={handleItemProps}
                  addToBasket={addToBasket}
                  stepNumber={ index + 1 }
                  title={title}
                  description={description}
                  name={id}
                  id={id}
                  price={typeof assemble_price === "string" ? parseInt(assemble_price) : assemble_price}
                  videoURL={getVideoUrl( video, isPriceOffer )}
                  isLastItem={false}
                  isResponsible={enable_diy}
                  responsible={diy_selected}
                  key={id}
                  data={data}
                  isTimerVisible={isTimerVisible}
                  isPriceOffer={isPriceOffer}
                  storyId={story.id}
                />
              );

            })}
            </div>
          )
        })}
      </div>  
      )

  // return (
  //   <div>
  //     {data.map((step, index) => {

  //       // last item ? isLastItem = true : isLastItem = false (check if last item)
  //       if (data.length - 1 === index) {
  //         return (
  //           <NavodyItemWrapper
  //             windowWidth={windowWidth}
  //             handleItemProps={handleItemProps}
  //             addToBasket={addToBasket}
  //             stepNumber={ index + 1 }
  //             title={step.title}
  //             description={step.description}
  //             name={step.id}
  //             id={step.id}
  //             price={typeof step.assemble_price === "string" ? parseInt(step.assemble_price) : step.assemble_price }
  //             videoURL={getVideoUrl( step.video, isPriceOffer )}
  //             isLastItem={true}
  //             isResponsible={step.enable_diy}
  //             responsible={step.diy_selected}
  //             key={step.id}
  //             data={data}
  //             isTimerVisible={isTimerVisible}
  //             isPriceOffer={isPriceOffer}
  //           />
  //         );
  //       }

  //       return (
  //         <NavodyItemWrapper
  //           windowWidth={windowWidth}
  //           handleItemProps={handleItemProps}
  //           addToBasket={addToBasket}
  //           stepNumber={ index + 1 }
  //           title={step.title}
  //           description={step.description}
  //           name={step.id}
  //           id={step.id}
  //           price={typeof step.assemble_price === "string" ? parseInt(step.assemble_price) : step.assemble_price}
  //           videoURL={getVideoUrl( step.video, isPriceOffer )}
  //           isLastItem={false}
  //           isResponsible={step.enable_diy}
  //           responsible={step.diy_selected}
  //           key={step.id}
  //           data={data}
  //           isTimerVisible={isTimerVisible}
  //           isPriceOffer={isPriceOffer}
  //         />
  //       );
  //     })}
  //   </div>
  // );

}

export default NavodyVideos;
