import React, { useEffect } from 'react';
import Menu from '../components/menu/Menu';
import { DashboardWrapper } from '../components/basic/BasicStyles';
import { useDispatch } from 'react-redux'
import { fetchData } from '../actions/MainActions';
import { useCookies } from 'react-cookie';

const DashboardScreen = ({ children }) => {
  const token = localStorage.getItem('token')
  const [cookies, setCookie] = useCookies(['jwt-token']);
  const dispatch = useDispatch()
  useEffect(() => {
    
    const options = {
      method: 'GET',
      headers: {
        'Authorization' : `Bearer ${ localStorage.getItem('token') || null }`
      }
    }
    const url = `/api/usik/index`

    dispatch( fetchData( url, options ) )

    if(token) {
      setCookie('jwt-token', token, {
        secure: true,
        domain: '.banik.sk'
      })
    }
  }, []);

  return (
    <DashboardWrapper>
      <Menu isPriceOffer={false}/>
      {children}
    </DashboardWrapper>
  )
};

export default DashboardScreen;
