import React, { Component } from 'react';
import HorizontalLayout2 from '../components/horizontal-layout2/HorizontalLayout2';
import ErrorForm from '../components/form/Form';

class ErrorScreen extends Component {
  render() {
    return (
      <div>
        <HorizontalLayout2>
          <ErrorForm
            formTitle='CHYBA'
            formAbout={this.props.message}
            isPersonalInfoVisible={false}
            isAlreadyUser='false'
            submitButtonText='Späť'
            onSubmit={() => {
              this.props.navigate('/login');
            }}
            alreadyUserHref=''
            disabled={false}
          >
          </ErrorForm>
        </HorizontalLayout2>
      </div>
    );
  }
}

export default ErrorScreen;
