import React from 'react';
import { NavodyBasketSubmitButton } from './NavodyBasketStyles';

const NavodyBasketButton = ({ buttonText, onClick }) => (
  <NavodyBasketSubmitButton onClick={onClick} type='submit'>
    {buttonText}
  </NavodyBasketSubmitButton>
);

export default NavodyBasketButton;
