import { VALIDATE_CODE } from './Types';
import { LOADING_CODE_START } from './Types';
import { LOADING_CODE_END } from './Types';

const isCodeValid =  async ( code = null ) => {

    const options = {
      method: 'GET',
    }
    const USIK_URL = process.env.REACT_APP_USIK_URL
    let res = await fetch( `${USIK_URL}/api/usik/code/${code}`, options )
    let data = await res.json()
  
    if( code === null || data.status || !res.ok ) {
      return false
    }
    return true
  }
  
export const validateCode = ( code ) => async dispatch => {
    dispatch({ type: LOADING_CODE_START })
    try {
        const data = await isCodeValid( code )
        const payload = {
            isCodeValid: data,
            error: data === false ? true : false
        }
        dispatch({ type: VALIDATE_CODE, payload: payload})
    } catch(e) {
        console.log(e)
    } finally {
        dispatch({ type: LOADING_CODE_END})
    }
};