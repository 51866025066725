import React from 'react'
import {createUseStyles} from 'react-jss'

//styles pre button VEDLA stavoveho buttonu
const useStyles = createUseStyles({
    selectedActionButton: {
        outline: 'none',
        'font-size': '13px',
        fontWeight: 'bold',
        'text-align': 'left',
        padding: '0.75em 2em',
        margin: {
            right: '1em'
        },
        '@media (max-width: 768px)': {
            padding: {
                right: '1.5em',
                left: '1.5em',
                top: '0.75em',
                bottom: '0.75em'
            }
        },
        '@media (max-width: 678px)': {
                display: 'none'
        }
        ,
        background: enable_diy => enable_diy ? '#FFFFFF' : '#FEF7F2',
        border: '2px solid',
        borderColor: enable_diy => enable_diy ? '#3589FF' : '#EB6900',
        borderRadius: 4,
        color: enable_diy => enable_diy ? '#3589FF' : '#EB6900'
    }
})
//styles pre button NAD stavovym buttonom
const useStyles2 = createUseStyles({
    selectedActionButton: {
        display: 'none',
        outline: 'none',
        'font-size': '13px',
        fontWeight: 'bold',
        'text-align': 'left',
        padding: '0.75em 2em',
        margin: {
            right: '1em'
        },
        '@media (max-width: 678px)': {
                display: 'block',
                
                margin: {
                    bottom: '0.5em',
                    right: '0.75em',
                    top: '0',
                    left: 'auto'
                },
                padding: {
                    right: '1em',
                    left: '1em',
                    top: '0.75em',
                    bottom: '0.75em'
                }
        },
        '@media (max-width: 424px)': {
            padding: {
                right: '0.5em',
                left: '0.5em',
                top: '0.75em',
                bottom: '0.75em'
            }
    }
        ,
        background: enable_diy => enable_diy ? '#FFFFFF' : '#FEF7F2',
        border: '2px solid',
        borderColor: enable_diy => enable_diy ? '#3589FF' : '#EB6900',
        borderRadius: 4,
        color: enable_diy => enable_diy ? '#3589FF' : '#EB6900'
    }
})

const SelectedAction = ({type, disabled, data, visible}) => {

    const { enable_diy, diy_selected } = data
    const classes = useStyles( enable_diy )
    const classes2 = useStyles2( enable_diy )
    let text = enable_diy ? ( diy_selected ? "Spravím si sám" : "Nechám si spraviť" ) : "Odborná montáž"
    if(visible === false)
    return (
        <button 
            type={type}
            disabled={disabled} 
            className={classes2.selectedActionButton} 
        >
            {text}
        </button>
    )
    else
    return (
        <button 
            type={type}
            disabled={disabled} 
            className={classes.selectedActionButton} 
        >
            {text}
        </button>
    )
}

export default SelectedAction