const light = {
  white: '#fff',
  gray: '#E9E9F0',
  grayDash: '#F0F0F7',
  grayNavodyItem: '#FAFAFA',
  purple: '#A3A0FB'
};
const dark = {
  black: '#000',
  gray: '#4D4F5C'
};
const neutral = {
  purple: '#43425D',
  blue: '#3689FF',
  red: '#f00',
  orange: '#EB6900'
};
const theme = {
  light,
  dark,
  neutral
};
export default theme;
