import { IS_TIMER_VISIBLE, FETCH_DATA, CHANGE_CONSTRUCTOR } from './Types';

const processData = async ( url, options ) => {
  const USIK_URL = process.env.REACT_APP_USIK_URL
  const response = await fetch( `${USIK_URL}${url}`, options );
  
  if (!response.ok) {
    // const message = `An error has occured: ${response.status}`;
    const error = await response.json()
    return error
  }

  const data = await response.json();
  return data
};

export const fetchRoot = () => dispatch => {};

export const fetchData = (url, options, construction = null) => async dispatch => {

  let data = await processData( url, options )
  let selectedConstruction = data.length - 1

  let payload = {
    data,
    selectedConstruction: construction === null 
      ? (selectedConstruction >= 0 && selectedConstruction) 
      : construction
  }

  dispatch({ type: FETCH_DATA, payload: payload });
  
};

export const changeSelectedConstruction = index => {
  return {
    type: CHANGE_CONSTRUCTOR,
    payload: index
  };
};

export const handleTimerVisibility = boolean => {
  return {
    type: IS_TIMER_VISIBLE,
    payload: boolean
  };
};
