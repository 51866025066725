import { VALIDATE_CODE } from '../actions/Types';
import { LOADING_CODE_START } from '../actions/Types';
import { LOADING_CODE_END } from '../actions/Types';

const initialState = {
  isCodeValid: false,
  isLoading: true,
  error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
      case VALIDATE_CODE:
        return {
          ...state,
          isCodeValid: action.payload.isCodeValid,
          error: action.payload.error
        };
      case LOADING_CODE_START:
        return {
          ...state,
          isLoading: true 
        };
      case LOADING_CODE_END:
        return {
          ...state,
          isLoading: false 
        };
      default:
        return state;
    }
  }