import { combineReducers } from 'redux';
import MainReducer from './MainReducer';
import TokenReducer from './TokenReducer';
import RegisterReducer from './RegisterReducer';
import LoginReducer from './LoginReducer';
import CodeReducer from './CodeReducer'

export default () =>
  combineReducers({
    main: MainReducer,
    token: TokenReducer,
    register: RegisterReducer,
    login: LoginReducer,
    code: CodeReducer
  });
