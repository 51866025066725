import React, { Component } from 'react';
import HorizontalLayout2 from '../components/horizontal-layout2/HorizontalLayout2';
import ValidationForm from '../components/form/Form';
import FormInput from '../components/form/FormInput';
import { FormInputWrapper } from '../components/form/FormInputStyles';
import { validateCode } from '../actions/CodeActions';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';

class ValidationScreen extends Component {
  state = {
    code: '',
    isCode: false,
    message: ''
  };

  handleCodeInput = e => {
    this.setState({code: e.target.value})
    let codis = e.target.value.trim()
    this.setState({
      code: codis,
      isCode: e.target.value.length === 10,
      message: 'Kód musí obsahovať 10 znakov bez medzier'
    });
    if(e.target.value === '' || e.target.value.length === 10)
    {this.setState({message:''})}
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { isCode, code } = this.state;

    if ( isCode ) {
      await this.props.validateCode( code, this);
    } else {
      console.error('FORM INVALID -DISPLAY ERROR MESSAGE');
    }
  };

  render() {
    const { isCode, code } = this.state;
    const { isCodeValid, error } = this.props.code;
    
    if (error) {
      return (<Redirect noThrow to="/error/Kód nie je platný"/>);
    }

    if ( isCodeValid ) {
      return (<Redirect noThrow to={`/price-offer/${code}`}/>);
    }

    return (
      <div>
        <HorizontalLayout2>
          <ValidationForm
            formTitle='OVERENIE'
            formAbout='Zadajte prosím identifikačný kód'
            isPersonalInfoVisible={false}
            submitButtonText='Overiť'
            isAlreadyUser='false'
            alreadyUserHref=''
            onSubmit={this.handleSubmit}
            disabled={!isCode}
          >
            <FormInputWrapper>
              <FormInput
                placeholder='Identifikačný kód'
                type='text'
                required={true}
                onChange={e => this.handleCodeInput(e)}
                value={code.trim()}
              />
            </FormInputWrapper>
            <p style={{paddingTop:'5px', color:'red'}}>{this.state.message}</p>
          </ValidationForm>
        </HorizontalLayout2>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  code: state.code
});

const mapDispatchToProps = {
  validateCode: validateCode
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationScreen);
