import React, { Component } from 'react';
import Select from '../select/Select';
import { MenuWrapper, MenuContent } from './MenuStyles';
import { StyledSelectWrapper } from '../select/SelectStyles';
import UserProfile from '../user-profile/UserProfile';
// import user from '../../mock/user.json';
import { NavodyHeaderTokenWrapper } from '../navody/header/NavodyHeaderStyles';
import { connect } from 'react-redux';
import { handleTimerVisibility, changeSelectedConstruction } from '../../actions/MainActions';
import { logoutUser, removeToken } from '../../actions/LoginActions';
import { withCookies, Cookies } from 'react-cookie';

class Menu extends Component {
  state = {
    options: null,
    windowWidth: undefined,
    isTimerVisible: true
  };

  componentDidMount() {

    const { login, cookies } = this.props

    if ( login.token ) {
      localStorage.setItem( 'token', login.token ); 
      // cookies.set('jwt-token', login.token); 
      this.props.removeToken( login.data )
    }

    let options = []
    if( this.props.data.length > 0 ) {
      this.props.data.forEach( story => {
        const { id, name, job_id } = story
        const item = {
          id, 
          value: job_id, 
          label: name == null ? job_id : name
        }
        options = [ ...options, item ]
      });
    }
    
    this.setState({
      options
    })

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate( prevProps ) {

    if( ( (this.props.data !== prevProps.data) && prevProps.data === false ) || (this.props.selectedConstruction !== prevProps.selectedConstruction) ) {

      let options = []
      if( this.props.data.length > 0 ) {
        this.props.data.forEach( story => {

          const { id, name, job_id } = story
          const item = {
            id, 
            value: job_id, 
            label: name == null ? job_id : name
          }
          options = [ ...options, item ]
        });
      }
    
      this.setState({
        options
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleSelect = e => {
    const { options } = this.state
    let payload = 0 
    options.forEach( (option,index) => {
      if( e.id === option.id ) {
        payload = index 
      }
    })
    // console.log( payload )
    this.props.changeSelectedConstruction( payload )
  };

  handleResize = () => {
    this.setState({
      windowWidth: window.outerWidth
    });
  };

  handleLogout = () => {
    const {cookies} = this.props
    localStorage.removeItem('token')
    cookies.remove( 'jwt-token' )
    this.props.logoutUser()
    window.location.reload();
  }

  // handle timer visibilty if window width is less than 769px
  handleTimerVisibilityVisibility = () => {
    const { handleTimerVisibility } = this.props;
    handleTimerVisibility(false);
  };

  render() {
    const { windowWidth, options } = this.state;
    const { isTimerVisible, isPriceOffer, data, selectedConstruction, user } = this.props;

    if ( options != null && data && !isPriceOffer ) {

      return (
        // handle timer visibilty if window width is less than 769px
        <MenuWrapper isTimerVisible={isTimerVisible} isPriceOffer={isPriceOffer}>
          { !isPriceOffer && (
            <MenuContent>
            
            <StyledSelectWrapper>
              { options.length > 0 && (
                <Select
                  // default value of select is last construction in array of constructions
                  defaultValue={ options[selectedConstruction] }
                  // select options
                  options={options}
                  placeholder='Vyberte stavbu...'
                  // handle value of select
                  onChange={this.handleSelect}
                  windowWidth={windowWidth}
                />
              )}
            </StyledSelectWrapper>
              
              <UserProfile data={user} logoutButtonClick={this.handleLogout}>
                {/* <Notification data={notifications} /> */}
              </UserProfile>

            </MenuContent>
          )}

          {windowWidth < 769 && isTimerVisible && isPriceOffer &&(
            <NavodyHeaderTokenWrapper isPriceOffer={isPriceOffer} > 
              <span>Dočasný kód expiruje za 96:32:20</span>
              <svg
                onClick={this.handleTimerVisibilityVisibility}
                xmlns='http://www.w3.org/2000/svg'
                width='14'
                height='14'
                viewBox='0 0 14 14'
              >
                <g id='icon_close' transform='translate(0 0)'>
                  <rect
                    id='Rectangle_145'
                    data-name='Rectangle 145'
                    width='17.678'
                    height='2.121'
                    transform='translate(1.5 0) rotate(45)'
                    fill='#a3a0fb'
                  />
                  <rect
                    id='Rectangle_146'
                    data-name='Rectangle 146'
                    width='17.678'
                    height='2.121'
                    transform='translate(14 1.5) rotate(135)'
                    fill='#a3a0fb'
                  />
                </g>
              </svg>
            </NavodyHeaderTokenWrapper>
          )}

        </MenuWrapper>
      );
    }

    return null
  }
}

const mapStateToProps = state => ({
  isTimerVisible: state.main.isTimerVisible,
  login: state.login,
  data: state.main.data,
  selectedConstruction: state.main.selectedConstruction,
  user: state.token.user
});

const mapDispatchToProps = dispatch => ({
  handleTimerVisibility: boolean => dispatch(handleTimerVisibility(boolean)),
  removeToken: removeToken,
  changeSelectedConstruction: index => dispatch(changeSelectedConstruction( index )),
  logoutUser: logoutUser
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( withCookies(Menu) );
