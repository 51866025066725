import styled from 'styled-components';

export const ModalNavodyWrapper = styled.div``;

export const ModalNavodyButtonNext = styled.button`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  width: 100%;
  height: 50px;
  transition-property: color, background-color, border-radius;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  font-weight: bold;
  color: ${({ theme }) => theme.light.white};
  text-decoration: none;
  background-color: ${({ theme }) => theme.neutral.blue};
  color: ${({ theme }) => theme.light.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  &:focus {
    color: ${({ theme }) => theme.light.white};
    text-decoration: none;
  }
  &:hover {
    color: ${({ theme }) => theme.light.white};
    text-decoration: none;
  }
`;
