import styled from 'styled-components';

export const MenuWrapper = styled.nav`
  display: ${({ isPriceOffer }) => ( isPriceOffer ? 'none' : 'block' )};
  padding: 1em;
  height: 72px;
  background-color: ${({ theme }) => theme.light.white};
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  width: 100%;
  position: relative;
  z-index: 10;
  transition-property: height, padding;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  @media (max-width: 768px) {
    //height: ${({ isTimerVisible, isPriceOffer }) => ( isPriceOffer ? ( isTimerVisible ? '76px' : '0' ) : ( isTimerVisible ? '123px' : '72px' ) )};
    padding: ${({ isTimerVisible, isPriceOffer }) => ( isPriceOffer ? ( isTimerVisible ? '1em' : '0' ) : '1em' )};
    display: flex;
    flex-direction: column;
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  @media (max-width: 320px) {
    //height: ${({ isTimerVisible, isPriceOffer }) => ( isPriceOffer ? ( isTimerVisible ? '60px' : '0' ) : ( isTimerVisible ? '107px' : '56px' ) )};
    padding: ${({ isTimerVisible, isPriceOffer }) => ( isPriceOffer ? ( isTimerVisible ? '0.5em' : '0' ) : '0.5em' )};
  }
`;

export const MenuContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
