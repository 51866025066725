import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const NavodyItemWrapper = styled.div``;

// HEADER
export const NavodyItemHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.light.white};
  // ${({ isPriceOffer }) => isPriceOffer ? `padding: 0.75em;` : `padding: 0 0 .75em .75em;`}
  padding: 0.75em;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 374px) {
    padding: 0.5em;
  }
`;

// HEADER LEFT
export const NavodyItemHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 769px) {
    width: 35%;
  }
`;

export const NavodyItemHeaderOrderNo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  width: 47px;
  height: 47px;
  border: 5px solid #f2f2f2;
  border-radius: 50%;
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
`;

export const NavodyItemHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  h6 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  @media (max-width: 1024px) {
    margin-left: 20px;
  }
  @media (max-width: 678px) {${({ isPriceOffer }) => isPriceOffer ? `max-width: 100%` : `max-width: 45%`}}
  @media (max-width: 374px) {
    margin-left: 10px;
  }
  @media (max-width: 320px) {
    ${({ isPriceOffer }) => isPriceOffer ? `max-width: 100%` : `max-width: 40%`}
    margin-bottom: 5px;
  }
`;

// HEADER RIGHT
export const NavodyItemHeaderRight = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 769px) {
    width: 65%;
    justify-content: flex-end;
  }
  @media (max-width: 768px) {
    margin-top: 0.75em;
    flex-direction: row-reverse;
  }
  @media (max-width: 374px) {
    margin-top: 0.5em;
  }
`;

export const NavodyItemHeaderButtonWrapper = styled.form`
  display: flex;
  margin-left: 20px;
  @media (max-width: 374px) {
    margin-left: 10px;
  }
`;

export const NavodyItemHeaderButton = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.light.white};
  cursor: pointer;
  &:first-child {
    label {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  &:last-child {
    label {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  input {
    display: none;
    &:checked + label {
      color: ${({ theme }) => theme.neutral.blue};
      font-weight: 700;
      border: 2px solid ${({ theme }) => theme.neutral.blue};
    }
    &:disabled + label {
      color: ${({ theme }) => theme.neutral.orange};
      background-color: rgba(235, 105, 0, 0.05);
      border: 2px solid ${({ theme }) => theme.neutral.orange};
      cursor: not-allowed;
    }
  }

  label {
    border: 1px solid #d7dae2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ isDisabled }) => (isDisabled ? '360px' : '180px')};
    height: 40px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    @media (max-width: 1024px) {
      width: ${({ isDisabled }) => (isDisabled ? '300px' : '150px')};
    }
    @media (max-width: 768px) {
      font-size: 12px;
      width: ${({ isDisabled }) => (isDisabled ? '280px' : '140px')};
      height: 34px;
    }
    @media (max-width: 374px) {
      width: ${({ isDisabled }) => (isDisabled ? '240px' : '120px')};
      font-size: 11px;
    }
  }
`;

export const NavodyItemHeaderPriceWrapper = styled.div`
  color: ${({ theme }) => theme.neutral.purple};
  margin-left: 1.5em;
  line-height: 18px;
  div {
    font-size: 13px;
    text-align: right;
  }
  span {
    font-size: 15px;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    display: flex;
    margin-left: 0em;

    span {
      font-size: 13px;
      margin-left: 0.25em;
    }
  }
`;

export const NavodyItemNumberFormat = styled(NumberFormat)`
  margin: 0;
  font-weight: bold;
  padding: 0;
  border: 0;
  background-color: ${({ theme }) => theme.light.white};
  outline: 0;
  max-width: 75px;
  text-align: ${({ alignleft }) => (alignleft ? 'left' : 'right')};
`;

export const NavodyItemHeaderArrow = styled.div`
  //margin: 0 1em 0 1.5em;
  cursor: pointer;
  @media (max-width: 768px) {
    margin: 0;
  }
  svg {
    transition: transform 0.2s ease-in-out;
    fill: #808495;
  }
`;

// BODY
export const NavodyItemBody = styled.div`
  background-color: ${({ theme }) => theme.light.grayNavodyItem};
  display: flex;
  @media (max-width: 678px) {
    flex-direction: column-reverse;
  }
`;

export const NavodyItemBodyContent = styled.div`
  padding: 2em;
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
  flex: 2;
  transition: flex 0.2s ease-in-out;
  @media (max-width: 1200px) {
    flex: 1;
  }
  @media (max-width: 768px) {
    max-height: initial;
    height: initial;
    padding: 1em 1em 1em 1em;
    
  }
  @media (max-width: 678px) {
    padding-bottom: 10px;
  }
  @media (max-width: 424px) {
    padding: 1em 1em 1em 0;
  }
`;

export const NavodyItemBodyTitle = styled.h1`
  font-size: 25px;
  color: ${({ theme }) => theme.neutral.purple};
  font-weight: 700;
  @media (max-width: 424px) {
      margin-left: 15px;
  }
`;

export const NavodyItemBodySubTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 424px) {
    margin-left: 15px;
}
`;

export const NavodyItemBodyText = styled.div`
  font-size: 15px;
  font-weight: 400;
  @media (max-width: 424px) {
    margin-left: 15px;
}
`;

export const NavodyItemBodyPlayer = styled.div`
  width: 100%;
  flex: 1;
  @media (max-width: 678px) {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 0.5em;
  }
  div {
    div {
      video {
        height: 200px;
        @media all and (display-mode: fullscreen) {
          height: 100%;
        }
        @media (max-width: 768px) {
          width: 100% !imortant;
          //margin: 0 15%;
        }
        @media (max-width: 768px) and (orientation: landscape) {
          //width: 60%;
          //margin: 0 20%;
        }
        @media (max-width: 550px) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`;

// FOOTER
export const NavodyItemFooter = styled.div`
  height: 20px;
  position: relative;
  span {
    height: 100%;
    width: 2px;
    background: #a5a4bf;
    display: inline-block;
    margin-left: 34.5px;
    @media (max-width: 768px) {
      margin-left: 30.5px;
    }
    @media (max-width: 374px) {
      margin-left: 27.5px;
    }
  }
`;
