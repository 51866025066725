import React from 'react';
import styled from 'styled-components';

const Check = ({header, text, url, urlText}) => (
    <CheckWrapper>
        <svg id="check" xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
            <g id="background" fill="none" stroke="#3b86ff" strokeWidth="5">
                <rect width="47" height="47" rx="23.5" stroke="none"/>
                <rect x="2.5" y="2.5" width="42" height="42" rx="21" fill="none"/>
            </g>
            <path id="Path_319" data-name="Path 319" d="M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z" transform="translate(16 18)" fill="#3b86ff"/>
        </svg>
        <h2 className='header'>{header}</h2>
        <p className='text'>{text}</p>
        { (url && urlText) && (
            // <a onClick={() => gtag('event', 'vypocitat-cenovu-ponuku', {'event_category': 'buttonclick'})} href={url}>{urlText}</a>
            <a href={url}>{urlText}</a>
        )} 
    </CheckWrapper>
)

export default Check;

const CheckWrapper = styled.div`

position: absolute;
top:50%;
left:50%;
transform: translate(-50%, -50%);
width: 90%;
max-width: 770px;
margin: 0 auto;

background-color: white;
-webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.04);
-moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.04);
box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.04);

padding: 50px 0;

font-family: 'Source Sans Pro', sans-serif;
font-size: 15px;
line-height: 1.5em;
color: #4d4f5c;
text-align: center;

> svg {
    margin-bottom: 2em;
}

> h2.header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2em;
}

> p.text {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 2em;
}

> a {
    text-decoration: none;
    color: #3b86ff;
}

> a:hover {
    text-decoration: underline;
}
`