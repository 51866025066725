import styled from 'styled-components';

export const NavodyCustomWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 3em 1em;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding: 1em;
  }
  @media (max-width: 425px) {
    padding: 20px 0;
  }
`;

export const RatingWrapper = styled.span`
  margin-right: 2px;
  display: flex;
  align-items: center;
  height: 20px;
`;
