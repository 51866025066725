import { LOGIN } from '../actions/Types';
import { LOGOUT } from '../actions/Types';
import { REMOVE_TOKEN } from '../actions/Types';

const initialState = {
  data: null,
  token: null,
  error: false,
  isLogged: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        data: action.payload.data,
        token: action.payload.token,
        error: action.payload.error,
        isLogged: action.payload.isLogged
      };
    case LOGOUT:
      return {
        ...state,
        data: action.payload.data,
        token: action.payload.token,
        error: action.payload.error,
        isLogged: action.payload.isLogged
      };
      case REMOVE_TOKEN:
        return {
          ...state,
          data: action.payload.data,
          token: action.payload.token,
          error: action.payload.error,
          isLogged: action.payload.isLogged
        };
    default:
      return state;
  }
}
