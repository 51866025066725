import { FETCH_ROOT, FETCH_DATA, IS_TIMER_VISIBLE, CHANGE_CONSTRUCTOR } from '../actions/Types';

const initialState = {
  root: {},
  data: false,
  selectedConstruction: null,
  isTimerVisible: true
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ROOT:
      return {
        ...state
      };
    case FETCH_DATA:
      return {
        ...state,
        data: action.payload.data,
        selectedConstruction: action.payload.selectedConstruction
      };
    case CHANGE_CONSTRUCTOR:
      return { ...state, selectedConstruction: action.payload };
    case IS_TIMER_VISIBLE:
      return { ...state, isTimerVisible: action.payload };

    default:
      return state;
  }
}
