import React from 'react';
import { Router } from '@reach/router';
import LandingScreen from '../screens/LandingScreen';
import ValidationScreen from '../screens/ValidationScreen';
import RegistrationScreen from '../screens/RegistrationScreen';
import LoginScreen from '../screens/LoginScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import RecoverPasswordScreen from '../screens/RecoverPasswordScreen';
import DashboardScreen from '../screens/DashboardScreen';
import PriceOfferScreen from '../screens/PriceOfferScreen';
import NavodyScreen from '../screens/NavodyScreen';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ErrorScreen from '../screens/ErrorScreen';
import RegistrationSuccessScreen from '../screens/RegistrationSuccess';
import RecoverSuccessScreen from '../screens/RecoverSuccess';

const NotFound = () => <div style={{margin: '0 auto', textAlign: 'center'}}>Sorry, nothing here.</div>;
// const Test1 = () => <div>test1</div>;
// const Test2 = () => <div>test2</div>;

const routes = (
  <Router>
    {/* <LandingScreen path='/' /> */}
    <NotFound default />
    <ErrorScreen path="/error/:message" />
    
    
    <RegistrationScreen path='registration' />
    <RegistrationSuccessScreen path='registration-success' />
    
    <ForgotPasswordScreen path='forgotPassword' />
    <RecoverPasswordScreen path='reset-password' />
    <RecoverSuccessScreen path='recover-success' />

    <PublicRoute path='login' component={LoginScreen} />
    <PublicRoute path='/' component={LoginScreen} />

    <ValidationScreen path='/price-offer' />
    <PriceOfferScreen path='price-offer/:code'>
      {/* <ValidationScreen path='/' /> */}
      <NavodyScreen path='/' isPriceOffer={true} />
      <NotFound default />
    </PriceOfferScreen>

    <PrivateRoute path='dashboard' component={DashboardScreen}>
      <NavodyScreen path='/' isPriceOffer={false} />
      {/* <Test2 path='test2' /> */}
      <NotFound default />
    </PrivateRoute>

  </Router>
);

export default routes;
