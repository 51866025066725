import React from 'react';
import { FormInputType } from './FormInputStyles';

const FormInput = ({
  placeholder,
  type,
  required,
  onChange,
  name,
  className,
  value
}) => (
  <FormInputType
    placeholder={placeholder}
    type={type}
    required={required}
    onChange={onChange}
    name={name}
    className={className}
    delayTimeout={1000}
    value={value}
  />
);

export default FormInput;
