import styled from 'styled-components';

export const HorizontalLayout2Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    min-height: 100vh;
    height: 100%;
  }
`;

export const HorizontalLayout2Left = styled.div`
  background-color: ${({ theme }) => theme.neutral.purple};
  color: ${({ theme }) => theme.light.white};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HorizontalLayout2Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 3em;
  overflow-y: scroll;
  transition: padding 0.2s ease-in-out;
  @media (max-width: 1024px) {
    padding: 2em;
  }
  @media (max-width: 320px) {
    padding: 2em 1em;
  }
`;

export const Logo = styled.img`
  max-width: 130px;
  width: auto;
  margin-top: 40px;
`;
