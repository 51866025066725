import React from 'react';
import {
  NavodyHeaderWrapper,
  NavodyHeaderLeft,
  NavodyHeaderTitle,
  NavodyHeaderTokenWrapper,
  NavodyHeaderToken,
  NavodyHeaderRight,
  NavodyHeaderSvgWrapper
} from '../header/NavodyHeaderStyles';
import NumberFormat from 'react-number-format';

const NavodyHeader = ({
  isTitleEditable,
  onEditIconClick,
  onBlur,
  onKeyDown,
  data,
  onInputChange,
  constructionNumber,
  constructionName,
  originalPriceOffer,
  code,
  codeExpiration,
  isPriceOffer
}) => (
  <NavodyHeaderWrapper>
    <NavodyHeaderLeft>
      <NavodyHeaderTitle>
        {/* <span style={{fontSize: "1em", lineHeight: "2em", borderBottom: "none", marginRight: "1em"}}>{constructionNumber}</span> */}
        {/* onInputChange handle construction name */}
        { (isTitleEditable && !isPriceOffer ) ? (
          <input
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onChange={onInputChange}
            autoFocus
            type='text'
            value={constructionName === null ? "" : constructionName}
          />
        ) : (
            <span>{constructionName === null ? `${constructionNumber} - Projekt` : `${constructionNumber} - ${constructionName}`}</span>
        )}
        {/* on icon click handle if input is editable (construction name) */}
        {
          !isPriceOffer && (
            <NavodyHeaderSvgWrapper
          onClick={onEditIconClick}
          isTitleEditable={isTitleEditable}
        >
          {/* isTitleEditable ? icon = done : icon = pen */}
          { isTitleEditable ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='15.1'
              height='10.8'
              viewBox='0 0 15.1 10.8'
            >
              <path
                id='Path_319'
                data-name='Path 319'
                d='M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z'
                fill='#fff'
              />
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='14.399'
              height='14.399'
              viewBox='0 0 14.399 14.399'
            >
              <g id='icon_edit' transform='translate(-257 -162.601)'>
                <rect
                  id='Rectangle_324'
                  data-name='Rectangle 324'
                  width='14.399'
                  height='14.399'
                  transform='translate(257 162.601)'
                  fill='none'
                />
                <path
                  id='Path_110'
                  data-name='Path 110'
                  d='M7.338,2.769,2.215,8.03,0,14.4l6.369-2.077L11.492,7.2Zm6.507-.415-1.8-1.8a1.679,1.679,0,0,0-2.492,0L8.03,2.077l4.154,4.292,1.661-1.661A1.755,1.755,0,0,0,14.4,3.461,1.709,1.709,0,0,0,13.846,2.354Z'
                  transform='translate(257 162.601)'
                  fill='#bcbccb'
                />
              </g>
            </svg>
          )}
        </NavodyHeaderSvgWrapper>
          )
        }
        
      </NavodyHeaderTitle>
      {
        isPriceOffer && (
          <NavodyHeaderTokenWrapper>
            <NavodyHeaderToken>#{code.toUpperCase()}</NavodyHeaderToken>
            <span>Dočasný kód expiruje {codeExpiration}</span>
          </NavodyHeaderTokenWrapper>
        )
      }
    </NavodyHeaderLeft>
    
    { isPriceOffer && (
      <NavodyHeaderRight>
        <div>
          <h3>
            <NumberFormat
              decimalSeparator={','}
              suffix={'€'}
              thousandSeparator={'.'}
              value={originalPriceOffer}
              renderText={value => <div>{value}</div>}
            />
          </h3>
          <div>Pôvodná cena práce</div>
        </div>
      </NavodyHeaderRight>
    )}

    
  </NavodyHeaderWrapper>
);

export default NavodyHeader;
