import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const NavodyBasketWrapper = styled.div`
  background-color: ${({ theme }) => theme.neutral.purple};
  display: flex;
  padding: 1em 0;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 90px;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavodyBasketWrapperResp = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: none;
  @media (max-width: 768px) {
    display: block;
    button {
      width: 100%;
      margin: 0;
      border-radius: 0;
      font-size: 15px;
      font-weight: bold;
      height: 60px;
    }
  }
`;

export const NavodyBasketContentResp = styled.div`
  background-color: ${({ theme }) => theme.neutral.purple};
  padding: 1em 0;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  @media (max-width: 425px) {
    height: 70px;
  }
  @media (max-width: 320px) {
    padding: 0 0.5em;
  }
`;

export const NavodyBasketHiddenContent = styled.div`
  position: absolute;
  transition-property: bottom;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  bottom: ${({ isBasketVisible }) => (isBasketVisible ? '99%' : '-100%')};
  left: 0;
  right: 0;
  width: 100%;
  padding: 1em;
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.neutral.purple};
  z-index: -1;
  @media (max-width: 320px) {
    padding: 0.5em;
    padding-bottom: 0;
  }
`;

export const NavodyBasketHiddenContentItem = styled.div`
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const NavodyBasketCounter = styled.div`
  color: ${({ theme }) => theme.light.white};
  line-height: 20px;
  display: flex;
  flex-direction: column;
  margin: 0 2em;
  font-weight: ${({ isbold }) => (isbold ? 700 : 400)};
  label {
    opacity: 0.66;
    font-size: 14px;
    margin: 0;
  }
  @media (max-width: 1200px) {
    margin: 0 1em;
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const NavodyBasketNumberFormat = styled(NumberFormat)`
  color: ${({ theme }) => theme.light.white};
  margin: 0;
  padding: 0;
  font-size: ${({ isbold }) => (isbold ? '25px' : '20px')};
  border: 0;
  background-color: ${({ theme }) => theme.neutral.purple};
  outline: 0;
  max-width: 150px;
  font-weight: ${({ isbold }) => (isbold ? 700 : 400)};
  @media (max-width: 1024px) {
    font-size: ${({ isbold }) => (isbold ? '20px' : '16px')};
    max-width: 100px;
  }
  @media (max-width: 768px) {
    font-size: ${({ isbold }) => (isbold ? '25px' : '20px')};
    max-width: 150px;
  }
  @media (max-width: 425px) {
    font-size: ${({ isbold }) => (isbold ? '20px' : '16px')};
  }
`;

export const NavodyBasketSubmitButton = styled.button`
  background-color: ${({ theme }) => theme.neutral.blue};
  border: 2px solid ${({ theme }) => theme.neutral.blue};
  color: ${({ theme }) => theme.light.white};
  height: 50px;
  padding: 0 3em;
  border-radius: 5px;
  font-size: 1em;
  margin: 0 2em;
  transition-property: color, background-color, border-radius;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.neutral.purple};
    border-radius: 0px;
  }
  @media (max-width: 1200px) {
    margin: 0 1em;
    padding: 0 1em;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    height: 50px !important;
    &:hover {
      background-color: ${({ theme }) => theme.neutral.blue};
      border-radius: 5px;
    }
  }
  @media (max-width: 425px) {
    height: 50px !important;
  }
`;
