export const FETCH_ROOT = 'FETCH_ROOT';
export const FETCH_DATA = 'FETCH_DATA';
export const CHANGE_CONSTRUCTOR = 'CHANGE_CONSTRUCTOR';
export const IS_TIMER_VISIBLE = 'IS_TIMER_VISIBLE';
export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const LOADING_TOKEN_START = 'LOADING_TOKEN_START';
export const LOADING_TOKEN_END = 'LOADING_TOKEN_END';
export const VALIDATE_CODE = 'VALIDATE_CODE';
export const LOADING_CODE_START = 'LOADING_CODE_START';
export const LOADING_CODE_END = 'LOADING_CODE_END';
