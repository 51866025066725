import React, { useEffect } from 'react';
import { Redirect } from '@reach/router';
import { isLogin } from '../utils/utils'
import { useDispatch, useSelector } from 'react-redux';
import { validateToken } from '../actions/TokenActions';



export default function PublicRoute({component: Component, ...rest}) {
  
  const isLoading = useSelector( state => state.token.isLoading )
  const isTokenValid = useSelector( state => state.token.isTokenValid )
  const token = localStorage.getItem('token')
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch( validateToken( token ) )
  }, [])

  return (
    isLoading ? (
      <></>
    ) : isTokenValid ? (
      <Redirect noThrow to='/dashboard' />
    ) : (
      <Component {...rest} />
    )
  );
}
