import { LOGIN } from './Types';
import { LOGOUT } from './Types';
import { REMOVE_TOKEN } from './Types';

const processData = async ( url, options ) => {

  const USIK_URL = process.env.REACT_APP_USIK_URL
  const response = await fetch( `${USIK_URL}${url}`, options );
  
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  const user = await response.json();
  return user
};

export const loginUser = (data) => async dispatch => {
  
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify( data )
  }
  
  try {
    let req = await processData( `/api/login`, options )
    let payload = {
      data,
      error: req == false ? true : false,
      token: req != false && req.token,
      isLogged: req.token != null ? true : false
    }
    window.localStorage.setItem( 'token', req.token )
    document.cookies=`jwt-token=${req.token}`
    dispatch({ type: LOGIN, payload: payload });
  } catch(e) {
    let payload = {
      data: null,
      error: true,
      token: null,
      isLogged: false
    }
    dispatch({ type: LOGIN, payload: payload });
  } 

};

export const removeToken = ( data ) => dispatch => {
  let payload = {
    data,
    token: null,
    error: false,
    isLogged: true
  }

  dispatch({ type: REMOVE_TOKEN, payload: payload });
}

export const logoutUser = () => dispatch => {

    let payload = {
      data: null,
      error: false,
      apiToken: null,
      isLogged: false
    }

    dispatch({ type: LOGOUT, payload: payload });
};