import React from 'react';
import {
  NavodyBasketCounter,
  NavodyBasketNumberFormat
} from './NavodyBasketStyles';

// reusable component for basket component used for rendering price from construction calculation (original price, save price, new price value)
const NavodyBasketItem = ({ value, renderText, labelText, prefix, isbold }) => (
  <NavodyBasketCounter isbold={isbold}>
    <label>{labelText}</label>
    {/* react component for formating number */}
    <NavodyBasketNumberFormat
      isbold={isbold}
      decimalSeparator={','}
      suffix={'€'}
      prefix={prefix}
      thousandSeparator={'.'}
      value={value}
      renderText={value => <div>{renderText}</div>}
    />
  </NavodyBasketCounter>
);

export default NavodyBasketItem;
